import { useTranslations } from '@vocab/react';
import { HiddenVisually } from 'braid-design-system';
import { memo, useCallback, type ReactNode } from 'react';

import type { SavedJob } from 'src/modules/graphql/queries/types';

import DashboardColumn, {
  type JobItem,
} from '../DashboardColumn/DashboardColumn';
import DashboardItem from '../DashboardItem/DashboardItem';

import translations from './.vocab';
import useSavedJobsDashboard from './useSavedJobsDashboard';

const SavedJobsDashboard = () => {
  const { t } = useTranslations(translations);
  const { displayState, data: savedJobs, totalCount } = useSavedJobsDashboard();

  const label = t('saved job label', { totalCount });
  const viewAllLinkProps = {
    analytics: 'dashboard-all-saved-jobs-click',
    location: '/my-activity/saved-jobs',
    linkContext: {
      linkSection: 'Saved Jobs',
    },
  };

  const renderJobItem = useCallback(
    (savedJob: JobItem, index: number) => {
      const {
        node: { id, job, isActive },
      } = savedJob as SavedJob;
      const {
        title,
        advertiser: { name: advertiser },
      } = job;
      const isExpired = !isActive;

      const translatedTitle = t('{title}. At {advertiser}', {
        title,
        advertiser,
        HiddenVisually: (children: ReactNode) => (
          <HiddenVisually key="screen-reader-only">{children}</HiddenVisually>
        ),
      });
      return (
        <DashboardItem
          key={id}
          isExpired={isExpired}
          headingProps={{
            analytics: 'dashboard-save-job-click',
            linkContext: {
              jobId: id,
              linkPosition: `${index + 1}`,
              linkSection: 'Saved Jobs',
            },
            location: {
              pathname: `/${isExpired ? 'expiredjob' : 'job'}/${id}`,
              query: {
                pos: index + 1,
                ref: 'saved-homepage',
              },
            },
          }}
          heading={title}
          screenReaderHeading={translatedTitle}
          subheading={advertiser}
        />
      );
    },
    [t],
  );

  return (
    <DashboardColumn
      type="savedJobs"
      dataAutomation="savedJobsDashboard"
      label={label}
      displayState={displayState}
      totalCount={totalCount}
      errorLinkProps={viewAllLinkProps}
      viewAllLinkProps={{
        ...viewAllLinkProps,
        screenReaderText: t('your saved jobs'),
      }}
      jobs={savedJobs}
      renderJobItem={renderJobItem}
      emptyMessage={t('Use the Save...')}
    />
  );
};

export default memo(SavedJobsDashboard);
