import type { TranslationKeys } from '@vocab/core';
import { useTranslations } from '@vocab/react';
import {
  HiddenVisually,
  IconArrow,
  Inline,
  Text,
  TextLink,
} from 'braid-design-system';
import { useCallback } from 'react';

import SkeletonLine from 'src/components/SkeletonLine/SkeletonLine';
import { useAnalyticsFacade } from 'src/modules/AnalyticsFacade';
import { useLocalisedLinks } from 'src/utils/melwaysHelper';

import translations from './.vocab';

type TranslationKey = TranslationKeys<typeof translations>;

interface ViewAllLinkProps {
  loading: boolean;
  count?: number;
  section: 'savedJobs' | 'savedSearches';
}

const getViewAllLinkData = (
  section: ViewAllLinkProps['section'],
): {
  viewAllLinkPath: string;
  screenReaderTextKey: TranslationKey;
  analytics: {
    linkSection: string;
    linkName: string;
  };
} => {
  switch (section) {
    case 'savedJobs':
      return {
        viewAllLinkPath: '/my-activity/saved-jobs',
        screenReaderTextKey: 'your saved jobs',
        analytics: {
          linkSection: 'Saved Jobs',
          linkName: 'dashboard-all-saved-jobs-click',
        },
      };
    case 'savedSearches':
      return {
        viewAllLinkPath: '/my-activity/saved-searches',
        screenReaderTextKey: 'your saved searches',
        analytics: {
          linkSection: 'Saved Searches',
          linkName: 'dashboard-all-saved-searches-click',
        },
      };
  }
};

export const ViewAllLink = ({ loading, count, section }: ViewAllLinkProps) => {
  const {
    screenReaderTextKey,
    viewAllLinkPath,
    analytics: { linkName, linkSection },
  } = getViewAllLinkData(section);
  const { t } = useTranslations(translations);
  const viewAllLinkHref = useLocalisedLinks({
    path: viewAllLinkPath,
  });
  const analytics = useAnalyticsFacade();

  const textLinkOnClick = useCallback(() => {
    analytics.linkClicked({
      linkContext: { linkSection },
      linkName,
      href: viewAllLinkHref,
    });
  }, [analytics, linkName, linkSection, viewAllLinkHref]);

  if (loading) {
    return (
      <Inline align="right" space="small">
        <SkeletonLine type="text" size="xsmall" width={145} height={38} />
      </Inline>
    );
  }

  return count ? (
    <Text align="right">
      <TextLink
        href={viewAllLinkHref}
        icon={<IconArrow direction="right" />}
        iconPosition="trailing"
        onClick={textLinkOnClick}
        weight="weak"
      >
        {t('View all ({count})', {
          count: count?.toString(),
        })}
      </TextLink>
      <HiddenVisually>{t(screenReaderTextKey)}</HiddenVisually>
    </Text>
  ) : null;
};
