import type { Locale } from '@seek/melways-sites';
import { useTranslations } from '@vocab/react';
import { Stack, Heading, TextLink, Text } from 'braid-design-system';
import { useCallback, useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';

import AnimatedHeight from 'src/components/AnimatedHeight/AnimatedHeight';
import { useAppConfig } from 'src/config/appConfig';
import {
  isBrowserAnalyticsFacade,
  useAnalyticsFacade,
} from 'src/modules/AnalyticsFacade';
import { saveSolReferencesForLoggedInHomeRecsAction } from 'src/store/results';
import { useLocalisedLinks } from 'src/utils/melwaysHelper';

import { CardsStack } from '../SharedComponent/CardsStack/CardsStack';
import { ErrorState } from '../SharedComponent/ErrorState/ErrorState';
import { JobCardSkeleton } from '../SharedComponent/JobCard/JobCardSkeleton';
import { MessageWrapper } from '../SharedComponent/MessageWrapper/MessageWrapper';

import translations from './.vocab';
import { RecommendedJobCard } from './RecommendedJobCard';
import {
  useHomeRecommendations,
  type RecommendedJobs,
} from './useHomeRecommendations';

const HOW_RECOMMENDATIONS_WORK_LINK_LOCALE_MAP: Record<Locale, string> = {
  'en-AU':
    'https://help.seek.com.au/s/article/Want-to-know-more-about-SEEK-Recommendations',
  'en-HK':
    'https://help.hk.jobsdb.com/s/article/Want-to-know-more-about-JobsDB-Recommendations-HK',
  'en-ID':
    'https://help.id.jobstreet.com/s/article/Want-to-know-more-about-Jobstreet-Recommendations-JS?language=en_US',
  'en-MY':
    'https://help.my.jobstreet.com/s/article/Want-to-know-more-about-Jobstreet-Recommendations-JS',
  'en-NZ':
    'https://help.seek.co.nz/s/article/Want-to-know-more-about-SEEK-Recommendations',
  'en-PH':
    'https://help.jobstreet.com.ph/s/article/Want-to-know-more-about-Jobstreet-Recommendations-JS',
  'en-SG':
    'https://help.sg.jobstreet.com/s/article/Want-to-know-more-about-Jobstreet-Recommendations-JS',
  'en-TH':
    'https://help.th.jobsdb.com/s/article/Want-to-know-more-about-JobsDB-Recommendations-TH?language=en_US',
  'id-ID':
    'https://help.id.jobstreet.com/s/article/Want-to-know-more-about-Jobstreet-Recommendations-JS?language=in',
  'th-TH':
    'https://help.th.jobsdb.com/s/article/Want-to-know-more-about-JobsDB-Recommendations-TH?language=th',
  'zh-HK':
    'https://help.hk.jobsdb.com/s/article/Want-to-know-more-about-JobsDB-Recommendations-HK',
};

const LOADING_SKELETON_COUNT = 3;
const LoadingRecommendations = () => (
  <CardsStack>
    {new Array(LOADING_SKELETON_COUNT).fill(null).map((_, index) => (
      <JobCardSkeleton key={index} />
    ))}
  </CardsStack>
);

const NoRecommendations = () => {
  const profileLink = useLocalisedLinks({
    path: '/profile/me',
  });
  const analyticsFacade = useAnalyticsFacade();

  const profileLinkOnClick = useCallback(() => {
    analyticsFacade.linkClicked({
      href: profileLink,
      linkName: 'dashboard-recommended-jobs-profile',
    });
  }, [analyticsFacade, profileLink]);

  const { t } = useTranslations(translations);

  return (
    <MessageWrapper>
      {t('Update your profile or start searching', {
        Link: (children) => (
          <TextLink
            weight="weak"
            target="_blank"
            href={profileLink}
            onClick={profileLinkOnClick}
          >
            {children}
          </TextLink>
        ),
      })}
    </MessageWrapper>
  );
};

const useTrackingRecommendedJobs = (
  recommendedJobs: RecommendedJobs | null | undefined,
) => {
  const [isViewedEventSent, setIsViewedEventSent] = useState(false);
  const analyticsFacade = useAnalyticsFacade();
  const dispatch = useDispatch();

  useEffect(() => {
    if (recommendedJobs && !isViewedEventSent) {
      if (isBrowserAnalyticsFacade(analyticsFacade)) {
        analyticsFacade.recommendedJobsImpression({
          solMetadata: recommendedJobs.solMetadata,
        });
      }
      setIsViewedEventSent(true);
    }
  }, [
    analyticsFacade,
    isViewedEventSent,
    recommendedJobs,
    setIsViewedEventSent,
  ]);

  useEffect(() => {
    const globalJobs = recommendedJobs?.globalJobs ?? [];
    if (globalJobs.length) {
      dispatch(saveSolReferencesForLoggedInHomeRecsAction(globalJobs));
    }
  }, [dispatch, recommendedJobs]);
};

const TopRecommendationsContent = () => {
  const { topJobs, loading, error } = useHomeRecommendations();

  if (loading) {
    return <LoadingRecommendations />;
  }

  if (error) {
    return <ErrorState section="recommendedJobs" />;
  }

  if (!topJobs.length) {
    return <NoRecommendations />;
  }

  return (
    <CardsStack>
      {topJobs.map((job, index) => (
        <RecommendedJobCard
          key={`recommendedJob_${job.id}`}
          jobInfo={job}
          position={index + 1}
        />
      ))}
    </CardsStack>
  );
};

export const TopRecommendations = () => {
  const { locale } = useAppConfig();
  const { t } = useTranslations(translations);
  const { loading, rawRecommendedJobs } = useHomeRecommendations();
  useTrackingRecommendedJobs(rawRecommendedJobs);

  return (
    <Stack space="medium">
      <Heading level="3">{t('Recommended jobs')}</Heading>
      <Text size="small" tone="secondary">
        {t('Based on your profile, job views and applications. Learn how', {
          Link: (children) => (
            <TextLink
              weight="weak"
              target="_blank"
              href={HOW_RECOMMENDATIONS_WORK_LINK_LOCALE_MAP[locale]}
            >
              {children}
            </TextLink>
          ),
        })}
      </Text>

      <AnimatedHeight changeKeys={[loading]}>
        <TopRecommendationsContent />
      </AnimatedHeight>
    </Stack>
  );
};
