import { memo, type ComponentProps } from 'react';

import useSolMetadataReference from 'src/modules/hooks/useSolMetadataReference';
import SolImpressionTracker from 'src/modules/solTracker/SolImpressionTracker';

import JobCard from '../SharedComponent/JobCard/JobCard';

const JobCardWithTracking = (props: ComponentProps<typeof JobCard>) => {
  const solMetadataReference = useSolMetadataReference({
    jobId: Number(props.jobInfo.id),
    displayType: 'loggedInHomeRecs',
  });

  return (
    <SolImpressionTracker
      jobId={props.jobInfo.id}
      solMetadata={props.jobInfo.solMetadata}
    >
      <JobCard
        {...props}
        location={{
          queryParams: { ref: 'recom-homepage', pos: String(props.position) },
          solHash: solMetadataReference,
        }}
        analytics={{
          linkName: 'dashboard-recommended-job-click',
          linkSection: 'Recommended Jobs',
        }}
      />
    </SolImpressionTracker>
  );
};

export const RecommendedJobCard = memo(JobCardWithTracking);
