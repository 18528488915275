
      import { createLanguage, createTranslationFile } from '@vocab/webpack/web';
      
    const translations = createTranslationFile({
      "en": createLanguage(
        require.resolveWeak("./translations.json-en-virtual.json!=!../../../../../../node_modules/.pnpm/virtual-resource-loader@1.0.1/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJSZWNvbW1lbmRlZCBqb2JzIjoiUmVjb21tZW5kZWQgZm9yIHlvdSIsIkJhc2VkIG9uIHlvdXIgcHJvZmlsZSwgam9iIHZpZXdzIGFuZCBhcHBsaWNhdGlvbnMuIExlYXJuIGhvdyI6IkJhc2VkIG9uIHlvdXIgcHJvZmlsZSwgam9iIHZpZXdzIGFuZCBhcHBsaWNhdGlvbnMuIExlYXJuIGhvdyA8TGluaz5yZWNvbW1lbmRhdGlvbnMgd29yazwvTGluaz4uIiwiTW9yZSBqb2JzIGZvciB5b3UiOiJNb3JlIGpvYnMgZm9yIHlvdSIsIlVwZGF0ZSB5b3VyIHByb2ZpbGUgb3Igc3RhcnQgc2VhcmNoaW5nIjoiPExpbms+VXBkYXRlIHlvdXIgcHJvZmlsZTwvTGluaz4gb3Igc3RhcnQgc2VhcmNoaW5nIGZvciBqb2JzIHRvIGdldCBwZXJzb25hbGlzZWQgam9iIHJlY29tbWVuZGF0aW9ucyBoZXJlLiJ9!"),
        () => import(
          /* webpackChunkName: "en-translations" */
          "./translations.json-en-virtual.json!=!../../../../../../node_modules/.pnpm/virtual-resource-loader@1.0.1/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJSZWNvbW1lbmRlZCBqb2JzIjoiUmVjb21tZW5kZWQgZm9yIHlvdSIsIkJhc2VkIG9uIHlvdXIgcHJvZmlsZSwgam9iIHZpZXdzIGFuZCBhcHBsaWNhdGlvbnMuIExlYXJuIGhvdyI6IkJhc2VkIG9uIHlvdXIgcHJvZmlsZSwgam9iIHZpZXdzIGFuZCBhcHBsaWNhdGlvbnMuIExlYXJuIGhvdyA8TGluaz5yZWNvbW1lbmRhdGlvbnMgd29yazwvTGluaz4uIiwiTW9yZSBqb2JzIGZvciB5b3UiOiJNb3JlIGpvYnMgZm9yIHlvdSIsIlVwZGF0ZSB5b3VyIHByb2ZpbGUgb3Igc3RhcnQgc2VhcmNoaW5nIjoiPExpbms+VXBkYXRlIHlvdXIgcHJvZmlsZTwvTGluaz4gb3Igc3RhcnQgc2VhcmNoaW5nIGZvciBqb2JzIHRvIGdldCBwZXJzb25hbGlzZWQgam9iIHJlY29tbWVuZGF0aW9ucyBoZXJlLiJ9!"
        )
      ),
"en-AU": createLanguage(
        require.resolveWeak("./translations.json-en-AU-virtual.json!=!../../../../../../node_modules/.pnpm/virtual-resource-loader@1.0.1/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJSZWNvbW1lbmRlZCBqb2JzIjoiUmVjb21tZW5kZWQgZm9yIHlvdSIsIkJhc2VkIG9uIHlvdXIgcHJvZmlsZSwgam9iIHZpZXdzIGFuZCBhcHBsaWNhdGlvbnMuIExlYXJuIGhvdyI6IkJhc2VkIG9uIHlvdXIgcHJvZmlsZSwgam9iIHZpZXdzIGFuZCBhcHBsaWNhdGlvbnMuIExlYXJuIGhvdyA8TGluaz5yZWNvbW1lbmRhdGlvbnMgd29yazwvTGluaz4uIiwiTW9yZSBqb2JzIGZvciB5b3UiOiJNb3JlIGpvYnMgZm9yIHlvdSIsIlVwZGF0ZSB5b3VyIHByb2ZpbGUgb3Igc3RhcnQgc2VhcmNoaW5nIjoiPExpbms+VXBkYXRlIHlvdXIgcHJvZmlsZTwvTGluaz4gb3Igc3RhcnQgc2VhcmNoaW5nIGZvciBqb2JzIHRvIGdldCBwZXJzb25hbGlzZWQgam9iIHJlY29tbWVuZGF0aW9ucyBoZXJlLiJ9!"),
        () => import(
          /* webpackChunkName: "en-AU-translations" */
          "./translations.json-en-AU-virtual.json!=!../../../../../../node_modules/.pnpm/virtual-resource-loader@1.0.1/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJSZWNvbW1lbmRlZCBqb2JzIjoiUmVjb21tZW5kZWQgZm9yIHlvdSIsIkJhc2VkIG9uIHlvdXIgcHJvZmlsZSwgam9iIHZpZXdzIGFuZCBhcHBsaWNhdGlvbnMuIExlYXJuIGhvdyI6IkJhc2VkIG9uIHlvdXIgcHJvZmlsZSwgam9iIHZpZXdzIGFuZCBhcHBsaWNhdGlvbnMuIExlYXJuIGhvdyA8TGluaz5yZWNvbW1lbmRhdGlvbnMgd29yazwvTGluaz4uIiwiTW9yZSBqb2JzIGZvciB5b3UiOiJNb3JlIGpvYnMgZm9yIHlvdSIsIlVwZGF0ZSB5b3VyIHByb2ZpbGUgb3Igc3RhcnQgc2VhcmNoaW5nIjoiPExpbms+VXBkYXRlIHlvdXIgcHJvZmlsZTwvTGluaz4gb3Igc3RhcnQgc2VhcmNoaW5nIGZvciBqb2JzIHRvIGdldCBwZXJzb25hbGlzZWQgam9iIHJlY29tbWVuZGF0aW9ucyBoZXJlLiJ9!"
        )
      ),
"en-NZ": createLanguage(
        require.resolveWeak("./translations.json-en-NZ-virtual.json!=!../../../../../../node_modules/.pnpm/virtual-resource-loader@1.0.1/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJSZWNvbW1lbmRlZCBqb2JzIjoiUmVjb21tZW5kZWQgZm9yIHlvdSIsIkJhc2VkIG9uIHlvdXIgcHJvZmlsZSwgam9iIHZpZXdzIGFuZCBhcHBsaWNhdGlvbnMuIExlYXJuIGhvdyI6IkJhc2VkIG9uIHlvdXIgcHJvZmlsZSwgam9iIHZpZXdzIGFuZCBhcHBsaWNhdGlvbnMuIExlYXJuIGhvdyA8TGluaz5yZWNvbW1lbmRhdGlvbnMgd29yazwvTGluaz4uIiwiTW9yZSBqb2JzIGZvciB5b3UiOiJNb3JlIGpvYnMgZm9yIHlvdSIsIlVwZGF0ZSB5b3VyIHByb2ZpbGUgb3Igc3RhcnQgc2VhcmNoaW5nIjoiPExpbms+VXBkYXRlIHlvdXIgcHJvZmlsZTwvTGluaz4gb3Igc3RhcnQgc2VhcmNoaW5nIGZvciBqb2JzIHRvIGdldCBwZXJzb25hbGlzZWQgam9iIHJlY29tbWVuZGF0aW9ucyBoZXJlLiJ9!"),
        () => import(
          /* webpackChunkName: "en-NZ-translations" */
          "./translations.json-en-NZ-virtual.json!=!../../../../../../node_modules/.pnpm/virtual-resource-loader@1.0.1/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJSZWNvbW1lbmRlZCBqb2JzIjoiUmVjb21tZW5kZWQgZm9yIHlvdSIsIkJhc2VkIG9uIHlvdXIgcHJvZmlsZSwgam9iIHZpZXdzIGFuZCBhcHBsaWNhdGlvbnMuIExlYXJuIGhvdyI6IkJhc2VkIG9uIHlvdXIgcHJvZmlsZSwgam9iIHZpZXdzIGFuZCBhcHBsaWNhdGlvbnMuIExlYXJuIGhvdyA8TGluaz5yZWNvbW1lbmRhdGlvbnMgd29yazwvTGluaz4uIiwiTW9yZSBqb2JzIGZvciB5b3UiOiJNb3JlIGpvYnMgZm9yIHlvdSIsIlVwZGF0ZSB5b3VyIHByb2ZpbGUgb3Igc3RhcnQgc2VhcmNoaW5nIjoiPExpbms+VXBkYXRlIHlvdXIgcHJvZmlsZTwvTGluaz4gb3Igc3RhcnQgc2VhcmNoaW5nIGZvciBqb2JzIHRvIGdldCBwZXJzb25hbGlzZWQgam9iIHJlY29tbWVuZGF0aW9ucyBoZXJlLiJ9!"
        )
      ),
"en-HK": createLanguage(
        require.resolveWeak("./translations.json-en-HK-virtual.json!=!../../../../../../node_modules/.pnpm/virtual-resource-loader@1.0.1/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJSZWNvbW1lbmRlZCBqb2JzIjoiUmVjb21tZW5kZWQgZm9yIHlvdSIsIkJhc2VkIG9uIHlvdXIgcHJvZmlsZSwgam9iIHZpZXdzIGFuZCBhcHBsaWNhdGlvbnMuIExlYXJuIGhvdyI6IkJhc2VkIG9uIHlvdXIgcHJvZmlsZSwgam9iIHZpZXdzIGFuZCBhcHBsaWNhdGlvbnMuIExlYXJuIGhvdyA8TGluaz5yZWNvbW1lbmRhdGlvbnMgd29yazwvTGluaz4uIiwiTW9yZSBqb2JzIGZvciB5b3UiOiJNb3JlIGpvYnMgZm9yIHlvdSIsIlVwZGF0ZSB5b3VyIHByb2ZpbGUgb3Igc3RhcnQgc2VhcmNoaW5nIjoiPExpbms+VXBkYXRlIHlvdXIgcHJvZmlsZTwvTGluaz4gb3Igc3RhcnQgc2VhcmNoaW5nIGZvciBqb2JzIHRvIGdldCBwZXJzb25hbGlzZWQgam9iIHJlY29tbWVuZGF0aW9ucyBoZXJlLiJ9!"),
        () => import(
          /* webpackChunkName: "en-HK-translations" */
          "./translations.json-en-HK-virtual.json!=!../../../../../../node_modules/.pnpm/virtual-resource-loader@1.0.1/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJSZWNvbW1lbmRlZCBqb2JzIjoiUmVjb21tZW5kZWQgZm9yIHlvdSIsIkJhc2VkIG9uIHlvdXIgcHJvZmlsZSwgam9iIHZpZXdzIGFuZCBhcHBsaWNhdGlvbnMuIExlYXJuIGhvdyI6IkJhc2VkIG9uIHlvdXIgcHJvZmlsZSwgam9iIHZpZXdzIGFuZCBhcHBsaWNhdGlvbnMuIExlYXJuIGhvdyA8TGluaz5yZWNvbW1lbmRhdGlvbnMgd29yazwvTGluaz4uIiwiTW9yZSBqb2JzIGZvciB5b3UiOiJNb3JlIGpvYnMgZm9yIHlvdSIsIlVwZGF0ZSB5b3VyIHByb2ZpbGUgb3Igc3RhcnQgc2VhcmNoaW5nIjoiPExpbms+VXBkYXRlIHlvdXIgcHJvZmlsZTwvTGluaz4gb3Igc3RhcnQgc2VhcmNoaW5nIGZvciBqb2JzIHRvIGdldCBwZXJzb25hbGlzZWQgam9iIHJlY29tbWVuZGF0aW9ucyBoZXJlLiJ9!"
        )
      ),
"en-TH": createLanguage(
        require.resolveWeak("./translations.json-en-TH-virtual.json!=!../../../../../../node_modules/.pnpm/virtual-resource-loader@1.0.1/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJSZWNvbW1lbmRlZCBqb2JzIjoiUmVjb21tZW5kZWQgZm9yIHlvdSIsIkJhc2VkIG9uIHlvdXIgcHJvZmlsZSwgam9iIHZpZXdzIGFuZCBhcHBsaWNhdGlvbnMuIExlYXJuIGhvdyI6IkJhc2VkIG9uIHlvdXIgcHJvZmlsZSwgam9iIHZpZXdzIGFuZCBhcHBsaWNhdGlvbnMuIExlYXJuIGhvdyA8TGluaz5yZWNvbW1lbmRhdGlvbnMgd29yazwvTGluaz4uIiwiTW9yZSBqb2JzIGZvciB5b3UiOiJNb3JlIGpvYnMgZm9yIHlvdSIsIlVwZGF0ZSB5b3VyIHByb2ZpbGUgb3Igc3RhcnQgc2VhcmNoaW5nIjoiPExpbms+VXBkYXRlIHlvdXIgcHJvZmlsZTwvTGluaz4gb3Igc3RhcnQgc2VhcmNoaW5nIGZvciBqb2JzIHRvIGdldCBwZXJzb25hbGlzZWQgam9iIHJlY29tbWVuZGF0aW9ucyBoZXJlLiJ9!"),
        () => import(
          /* webpackChunkName: "en-TH-translations" */
          "./translations.json-en-TH-virtual.json!=!../../../../../../node_modules/.pnpm/virtual-resource-loader@1.0.1/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJSZWNvbW1lbmRlZCBqb2JzIjoiUmVjb21tZW5kZWQgZm9yIHlvdSIsIkJhc2VkIG9uIHlvdXIgcHJvZmlsZSwgam9iIHZpZXdzIGFuZCBhcHBsaWNhdGlvbnMuIExlYXJuIGhvdyI6IkJhc2VkIG9uIHlvdXIgcHJvZmlsZSwgam9iIHZpZXdzIGFuZCBhcHBsaWNhdGlvbnMuIExlYXJuIGhvdyA8TGluaz5yZWNvbW1lbmRhdGlvbnMgd29yazwvTGluaz4uIiwiTW9yZSBqb2JzIGZvciB5b3UiOiJNb3JlIGpvYnMgZm9yIHlvdSIsIlVwZGF0ZSB5b3VyIHByb2ZpbGUgb3Igc3RhcnQgc2VhcmNoaW5nIjoiPExpbms+VXBkYXRlIHlvdXIgcHJvZmlsZTwvTGluaz4gb3Igc3RhcnQgc2VhcmNoaW5nIGZvciBqb2JzIHRvIGdldCBwZXJzb25hbGlzZWQgam9iIHJlY29tbWVuZGF0aW9ucyBoZXJlLiJ9!"
        )
      ),
"en-ID": createLanguage(
        require.resolveWeak("./translations.json-en-ID-virtual.json!=!../../../../../../node_modules/.pnpm/virtual-resource-loader@1.0.1/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJSZWNvbW1lbmRlZCBqb2JzIjoiUmVjb21tZW5kZWQgZm9yIHlvdSIsIkJhc2VkIG9uIHlvdXIgcHJvZmlsZSwgam9iIHZpZXdzIGFuZCBhcHBsaWNhdGlvbnMuIExlYXJuIGhvdyI6IkJhc2VkIG9uIHlvdXIgcHJvZmlsZSwgam9iIHZpZXdzIGFuZCBhcHBsaWNhdGlvbnMuIExlYXJuIGhvdyA8TGluaz5yZWNvbW1lbmRhdGlvbnMgd29yazwvTGluaz4uIiwiTW9yZSBqb2JzIGZvciB5b3UiOiJNb3JlIGpvYnMgZm9yIHlvdSIsIlVwZGF0ZSB5b3VyIHByb2ZpbGUgb3Igc3RhcnQgc2VhcmNoaW5nIjoiPExpbms+VXBkYXRlIHlvdXIgcHJvZmlsZTwvTGluaz4gb3Igc3RhcnQgc2VhcmNoaW5nIGZvciBqb2JzIHRvIGdldCBwZXJzb25hbGlzZWQgam9iIHJlY29tbWVuZGF0aW9ucyBoZXJlLiJ9!"),
        () => import(
          /* webpackChunkName: "en-ID-translations" */
          "./translations.json-en-ID-virtual.json!=!../../../../../../node_modules/.pnpm/virtual-resource-loader@1.0.1/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJSZWNvbW1lbmRlZCBqb2JzIjoiUmVjb21tZW5kZWQgZm9yIHlvdSIsIkJhc2VkIG9uIHlvdXIgcHJvZmlsZSwgam9iIHZpZXdzIGFuZCBhcHBsaWNhdGlvbnMuIExlYXJuIGhvdyI6IkJhc2VkIG9uIHlvdXIgcHJvZmlsZSwgam9iIHZpZXdzIGFuZCBhcHBsaWNhdGlvbnMuIExlYXJuIGhvdyA8TGluaz5yZWNvbW1lbmRhdGlvbnMgd29yazwvTGluaz4uIiwiTW9yZSBqb2JzIGZvciB5b3UiOiJNb3JlIGpvYnMgZm9yIHlvdSIsIlVwZGF0ZSB5b3VyIHByb2ZpbGUgb3Igc3RhcnQgc2VhcmNoaW5nIjoiPExpbms+VXBkYXRlIHlvdXIgcHJvZmlsZTwvTGluaz4gb3Igc3RhcnQgc2VhcmNoaW5nIGZvciBqb2JzIHRvIGdldCBwZXJzb25hbGlzZWQgam9iIHJlY29tbWVuZGF0aW9ucyBoZXJlLiJ9!"
        )
      ),
"en-SG": createLanguage(
        require.resolveWeak("./translations.json-en-SG-virtual.json!=!../../../../../../node_modules/.pnpm/virtual-resource-loader@1.0.1/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJSZWNvbW1lbmRlZCBqb2JzIjoiUmVjb21tZW5kZWQgZm9yIHlvdSIsIkJhc2VkIG9uIHlvdXIgcHJvZmlsZSwgam9iIHZpZXdzIGFuZCBhcHBsaWNhdGlvbnMuIExlYXJuIGhvdyI6IkJhc2VkIG9uIHlvdXIgcHJvZmlsZSwgam9iIHZpZXdzIGFuZCBhcHBsaWNhdGlvbnMuIExlYXJuIGhvdyA8TGluaz5yZWNvbW1lbmRhdGlvbnMgd29yazwvTGluaz4uIiwiTW9yZSBqb2JzIGZvciB5b3UiOiJNb3JlIGpvYnMgZm9yIHlvdSIsIlVwZGF0ZSB5b3VyIHByb2ZpbGUgb3Igc3RhcnQgc2VhcmNoaW5nIjoiPExpbms+VXBkYXRlIHlvdXIgcHJvZmlsZTwvTGluaz4gb3Igc3RhcnQgc2VhcmNoaW5nIGZvciBqb2JzIHRvIGdldCBwZXJzb25hbGlzZWQgam9iIHJlY29tbWVuZGF0aW9ucyBoZXJlLiJ9!"),
        () => import(
          /* webpackChunkName: "en-SG-translations" */
          "./translations.json-en-SG-virtual.json!=!../../../../../../node_modules/.pnpm/virtual-resource-loader@1.0.1/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJSZWNvbW1lbmRlZCBqb2JzIjoiUmVjb21tZW5kZWQgZm9yIHlvdSIsIkJhc2VkIG9uIHlvdXIgcHJvZmlsZSwgam9iIHZpZXdzIGFuZCBhcHBsaWNhdGlvbnMuIExlYXJuIGhvdyI6IkJhc2VkIG9uIHlvdXIgcHJvZmlsZSwgam9iIHZpZXdzIGFuZCBhcHBsaWNhdGlvbnMuIExlYXJuIGhvdyA8TGluaz5yZWNvbW1lbmRhdGlvbnMgd29yazwvTGluaz4uIiwiTW9yZSBqb2JzIGZvciB5b3UiOiJNb3JlIGpvYnMgZm9yIHlvdSIsIlVwZGF0ZSB5b3VyIHByb2ZpbGUgb3Igc3RhcnQgc2VhcmNoaW5nIjoiPExpbms+VXBkYXRlIHlvdXIgcHJvZmlsZTwvTGluaz4gb3Igc3RhcnQgc2VhcmNoaW5nIGZvciBqb2JzIHRvIGdldCBwZXJzb25hbGlzZWQgam9iIHJlY29tbWVuZGF0aW9ucyBoZXJlLiJ9!"
        )
      ),
"en-MY": createLanguage(
        require.resolveWeak("./translations.json-en-MY-virtual.json!=!../../../../../../node_modules/.pnpm/virtual-resource-loader@1.0.1/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJSZWNvbW1lbmRlZCBqb2JzIjoiUmVjb21tZW5kZWQgZm9yIHlvdSIsIkJhc2VkIG9uIHlvdXIgcHJvZmlsZSwgam9iIHZpZXdzIGFuZCBhcHBsaWNhdGlvbnMuIExlYXJuIGhvdyI6IkJhc2VkIG9uIHlvdXIgcHJvZmlsZSwgam9iIHZpZXdzIGFuZCBhcHBsaWNhdGlvbnMuIExlYXJuIGhvdyA8TGluaz5yZWNvbW1lbmRhdGlvbnMgd29yazwvTGluaz4uIiwiTW9yZSBqb2JzIGZvciB5b3UiOiJNb3JlIGpvYnMgZm9yIHlvdSIsIlVwZGF0ZSB5b3VyIHByb2ZpbGUgb3Igc3RhcnQgc2VhcmNoaW5nIjoiPExpbms+VXBkYXRlIHlvdXIgcHJvZmlsZTwvTGluaz4gb3Igc3RhcnQgc2VhcmNoaW5nIGZvciBqb2JzIHRvIGdldCBwZXJzb25hbGlzZWQgam9iIHJlY29tbWVuZGF0aW9ucyBoZXJlLiJ9!"),
        () => import(
          /* webpackChunkName: "en-MY-translations" */
          "./translations.json-en-MY-virtual.json!=!../../../../../../node_modules/.pnpm/virtual-resource-loader@1.0.1/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJSZWNvbW1lbmRlZCBqb2JzIjoiUmVjb21tZW5kZWQgZm9yIHlvdSIsIkJhc2VkIG9uIHlvdXIgcHJvZmlsZSwgam9iIHZpZXdzIGFuZCBhcHBsaWNhdGlvbnMuIExlYXJuIGhvdyI6IkJhc2VkIG9uIHlvdXIgcHJvZmlsZSwgam9iIHZpZXdzIGFuZCBhcHBsaWNhdGlvbnMuIExlYXJuIGhvdyA8TGluaz5yZWNvbW1lbmRhdGlvbnMgd29yazwvTGluaz4uIiwiTW9yZSBqb2JzIGZvciB5b3UiOiJNb3JlIGpvYnMgZm9yIHlvdSIsIlVwZGF0ZSB5b3VyIHByb2ZpbGUgb3Igc3RhcnQgc2VhcmNoaW5nIjoiPExpbms+VXBkYXRlIHlvdXIgcHJvZmlsZTwvTGluaz4gb3Igc3RhcnQgc2VhcmNoaW5nIGZvciBqb2JzIHRvIGdldCBwZXJzb25hbGlzZWQgam9iIHJlY29tbWVuZGF0aW9ucyBoZXJlLiJ9!"
        )
      ),
"en-PH": createLanguage(
        require.resolveWeak("./translations.json-en-PH-virtual.json!=!../../../../../../node_modules/.pnpm/virtual-resource-loader@1.0.1/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJSZWNvbW1lbmRlZCBqb2JzIjoiUmVjb21tZW5kZWQgZm9yIHlvdSIsIkJhc2VkIG9uIHlvdXIgcHJvZmlsZSwgam9iIHZpZXdzIGFuZCBhcHBsaWNhdGlvbnMuIExlYXJuIGhvdyI6IkJhc2VkIG9uIHlvdXIgcHJvZmlsZSwgam9iIHZpZXdzIGFuZCBhcHBsaWNhdGlvbnMuIExlYXJuIGhvdyA8TGluaz5yZWNvbW1lbmRhdGlvbnMgd29yazwvTGluaz4uIiwiTW9yZSBqb2JzIGZvciB5b3UiOiJNb3JlIGpvYnMgZm9yIHlvdSIsIlVwZGF0ZSB5b3VyIHByb2ZpbGUgb3Igc3RhcnQgc2VhcmNoaW5nIjoiPExpbms+VXBkYXRlIHlvdXIgcHJvZmlsZTwvTGluaz4gb3Igc3RhcnQgc2VhcmNoaW5nIGZvciBqb2JzIHRvIGdldCBwZXJzb25hbGlzZWQgam9iIHJlY29tbWVuZGF0aW9ucyBoZXJlLiJ9!"),
        () => import(
          /* webpackChunkName: "en-PH-translations" */
          "./translations.json-en-PH-virtual.json!=!../../../../../../node_modules/.pnpm/virtual-resource-loader@1.0.1/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJSZWNvbW1lbmRlZCBqb2JzIjoiUmVjb21tZW5kZWQgZm9yIHlvdSIsIkJhc2VkIG9uIHlvdXIgcHJvZmlsZSwgam9iIHZpZXdzIGFuZCBhcHBsaWNhdGlvbnMuIExlYXJuIGhvdyI6IkJhc2VkIG9uIHlvdXIgcHJvZmlsZSwgam9iIHZpZXdzIGFuZCBhcHBsaWNhdGlvbnMuIExlYXJuIGhvdyA8TGluaz5yZWNvbW1lbmRhdGlvbnMgd29yazwvTGluaz4uIiwiTW9yZSBqb2JzIGZvciB5b3UiOiJNb3JlIGpvYnMgZm9yIHlvdSIsIlVwZGF0ZSB5b3VyIHByb2ZpbGUgb3Igc3RhcnQgc2VhcmNoaW5nIjoiPExpbms+VXBkYXRlIHlvdXIgcHJvZmlsZTwvTGluaz4gb3Igc3RhcnQgc2VhcmNoaW5nIGZvciBqb2JzIHRvIGdldCBwZXJzb25hbGlzZWQgam9iIHJlY29tbWVuZGF0aW9ucyBoZXJlLiJ9!"
        )
      ),
"id": createLanguage(
        require.resolveWeak("./translations.json-id-virtual.json!=!../../../../../../node_modules/.pnpm/virtual-resource-loader@1.0.1/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJSZWNvbW1lbmRlZCBqb2JzIjoiUmVjb21tZW5kZWQgZm9yIHlvdSIsIkJhc2VkIG9uIHlvdXIgcHJvZmlsZSwgam9iIHZpZXdzIGFuZCBhcHBsaWNhdGlvbnMuIExlYXJuIGhvdyI6IkJhc2VkIG9uIHlvdXIgcHJvZmlsZSwgam9iIHZpZXdzIGFuZCBhcHBsaWNhdGlvbnMuIExlYXJuIGhvdyA8TGluaz5yZWNvbW1lbmRhdGlvbnMgd29yazwvTGluaz4uIiwiTW9yZSBqb2JzIGZvciB5b3UiOiJNb3JlIGpvYnMgZm9yIHlvdSIsIlVwZGF0ZSB5b3VyIHByb2ZpbGUgb3Igc3RhcnQgc2VhcmNoaW5nIjoiPExpbms+VXBkYXRlIHlvdXIgcHJvZmlsZTwvTGluaz4gb3Igc3RhcnQgc2VhcmNoaW5nIGZvciBqb2JzIHRvIGdldCBwZXJzb25hbGlzZWQgam9iIHJlY29tbWVuZGF0aW9ucyBoZXJlLiJ9!"),
        () => import(
          /* webpackChunkName: "id-translations" */
          "./translations.json-id-virtual.json!=!../../../../../../node_modules/.pnpm/virtual-resource-loader@1.0.1/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJSZWNvbW1lbmRlZCBqb2JzIjoiUmVjb21tZW5kZWQgZm9yIHlvdSIsIkJhc2VkIG9uIHlvdXIgcHJvZmlsZSwgam9iIHZpZXdzIGFuZCBhcHBsaWNhdGlvbnMuIExlYXJuIGhvdyI6IkJhc2VkIG9uIHlvdXIgcHJvZmlsZSwgam9iIHZpZXdzIGFuZCBhcHBsaWNhdGlvbnMuIExlYXJuIGhvdyA8TGluaz5yZWNvbW1lbmRhdGlvbnMgd29yazwvTGluaz4uIiwiTW9yZSBqb2JzIGZvciB5b3UiOiJNb3JlIGpvYnMgZm9yIHlvdSIsIlVwZGF0ZSB5b3VyIHByb2ZpbGUgb3Igc3RhcnQgc2VhcmNoaW5nIjoiPExpbms+VXBkYXRlIHlvdXIgcHJvZmlsZTwvTGluaz4gb3Igc3RhcnQgc2VhcmNoaW5nIGZvciBqb2JzIHRvIGdldCBwZXJzb25hbGlzZWQgam9iIHJlY29tbWVuZGF0aW9ucyBoZXJlLiJ9!"
        )
      ),
"th": createLanguage(
        require.resolveWeak("./translations.json-th-virtual.json!=!../../../../../../node_modules/.pnpm/virtual-resource-loader@1.0.1/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJSZWNvbW1lbmRlZCBqb2JzIjoiUmVjb21tZW5kZWQgZm9yIHlvdSIsIkJhc2VkIG9uIHlvdXIgcHJvZmlsZSwgam9iIHZpZXdzIGFuZCBhcHBsaWNhdGlvbnMuIExlYXJuIGhvdyI6IkJhc2VkIG9uIHlvdXIgcHJvZmlsZSwgam9iIHZpZXdzIGFuZCBhcHBsaWNhdGlvbnMuIExlYXJuIGhvdyA8TGluaz5yZWNvbW1lbmRhdGlvbnMgd29yazwvTGluaz4uIiwiTW9yZSBqb2JzIGZvciB5b3UiOiJNb3JlIGpvYnMgZm9yIHlvdSIsIlVwZGF0ZSB5b3VyIHByb2ZpbGUgb3Igc3RhcnQgc2VhcmNoaW5nIjoiPExpbms+VXBkYXRlIHlvdXIgcHJvZmlsZTwvTGluaz4gb3Igc3RhcnQgc2VhcmNoaW5nIGZvciBqb2JzIHRvIGdldCBwZXJzb25hbGlzZWQgam9iIHJlY29tbWVuZGF0aW9ucyBoZXJlLiJ9!"),
        () => import(
          /* webpackChunkName: "th-translations" */
          "./translations.json-th-virtual.json!=!../../../../../../node_modules/.pnpm/virtual-resource-loader@1.0.1/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJSZWNvbW1lbmRlZCBqb2JzIjoiUmVjb21tZW5kZWQgZm9yIHlvdSIsIkJhc2VkIG9uIHlvdXIgcHJvZmlsZSwgam9iIHZpZXdzIGFuZCBhcHBsaWNhdGlvbnMuIExlYXJuIGhvdyI6IkJhc2VkIG9uIHlvdXIgcHJvZmlsZSwgam9iIHZpZXdzIGFuZCBhcHBsaWNhdGlvbnMuIExlYXJuIGhvdyA8TGluaz5yZWNvbW1lbmRhdGlvbnMgd29yazwvTGluaz4uIiwiTW9yZSBqb2JzIGZvciB5b3UiOiJNb3JlIGpvYnMgZm9yIHlvdSIsIlVwZGF0ZSB5b3VyIHByb2ZpbGUgb3Igc3RhcnQgc2VhcmNoaW5nIjoiPExpbms+VXBkYXRlIHlvdXIgcHJvZmlsZTwvTGluaz4gb3Igc3RhcnQgc2VhcmNoaW5nIGZvciBqb2JzIHRvIGdldCBwZXJzb25hbGlzZWQgam9iIHJlY29tbWVuZGF0aW9ucyBoZXJlLiJ9!"
        )
      ),
"id-ID": createLanguage(
        require.resolveWeak("./translations.json-id-ID-virtual.json!=!../../../../../../node_modules/.pnpm/virtual-resource-loader@1.0.1/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJSZWNvbW1lbmRlZCBqb2JzIjoiRGlyZWtvbWVuZGFzaWthbiB1bnR1ayBrYW11IiwiQmFzZWQgb24geW91ciBwcm9maWxlLCBqb2Igdmlld3MgYW5kIGFwcGxpY2F0aW9ucy4gTGVhcm4gaG93IjoiQmVyZGFzYXJrYW4gcHJvZmlsLCB0YW1waWxhbiBwZWtlcmphYW4sIGRhbiBhcGxpa2FzaSBBbmRhLiBQZWxhamFyaSBjYXJhIDxMaW5rPmtlcmphIHJla29tZW5kYXNpPC9MaW5rPi4iLCJNb3JlIGpvYnMgZm9yIHlvdSI6IkxlYmloIGJhbnlhayBwZWtlcmphYW4gdW50dWsgQW5kYSIsIlVwZGF0ZSB5b3VyIHByb2ZpbGUgb3Igc3RhcnQgc2VhcmNoaW5nIjoiPExpbms+UGVyYmFydWkgcHJvZmlsIEFuZGE8L0xpbms+IGF0YXUgbXVsYWkgbWVuY2FyaSBsb3dvbmdhbiB1bnR1ayBtZW5kYXBhdGthbiByZWtvbWVuZGFzaSBsb3dvbmdhbiB5YW5nIGRpcGVyc29uYWxpc2FzaSBkaSBzaW5pLiJ9!"),
        () => import(
          /* webpackChunkName: "id-ID-translations" */
          "./translations.json-id-ID-virtual.json!=!../../../../../../node_modules/.pnpm/virtual-resource-loader@1.0.1/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJSZWNvbW1lbmRlZCBqb2JzIjoiRGlyZWtvbWVuZGFzaWthbiB1bnR1ayBrYW11IiwiQmFzZWQgb24geW91ciBwcm9maWxlLCBqb2Igdmlld3MgYW5kIGFwcGxpY2F0aW9ucy4gTGVhcm4gaG93IjoiQmVyZGFzYXJrYW4gcHJvZmlsLCB0YW1waWxhbiBwZWtlcmphYW4sIGRhbiBhcGxpa2FzaSBBbmRhLiBQZWxhamFyaSBjYXJhIDxMaW5rPmtlcmphIHJla29tZW5kYXNpPC9MaW5rPi4iLCJNb3JlIGpvYnMgZm9yIHlvdSI6IkxlYmloIGJhbnlhayBwZWtlcmphYW4gdW50dWsgQW5kYSIsIlVwZGF0ZSB5b3VyIHByb2ZpbGUgb3Igc3RhcnQgc2VhcmNoaW5nIjoiPExpbms+UGVyYmFydWkgcHJvZmlsIEFuZGE8L0xpbms+IGF0YXUgbXVsYWkgbWVuY2FyaSBsb3dvbmdhbiB1bnR1ayBtZW5kYXBhdGthbiByZWtvbWVuZGFzaSBsb3dvbmdhbiB5YW5nIGRpcGVyc29uYWxpc2FzaSBkaSBzaW5pLiJ9!"
        )
      ),
"th-TH": createLanguage(
        require.resolveWeak("./translations.json-th-TH-virtual.json!=!../../../../../../node_modules/.pnpm/virtual-resource-loader@1.0.1/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJSZWNvbW1lbmRlZCBqb2JzIjoi4LiV4Liz4LmB4Lir4LiZ4LmI4LiH4LiH4Liy4LiZ4LmB4LiZ4Liw4LiZ4Liz4Liq4Liz4Lir4Lij4Lix4Lia4LiE4Li44LiTIiwiQmFzZWQgb24geW91ciBwcm9maWxlLCBqb2Igdmlld3MgYW5kIGFwcGxpY2F0aW9ucy4gTGVhcm4gaG93Ijoi4LiC4Li24LmJ4LiZ4Lit4Lii4Li54LmI4LiB4Lix4Lia4LmC4Lib4Lij4LmE4Lif4Lil4LmMIOC4oeC4uOC4oeC4oeC4reC4h+C4h+C4suC4mSDguYHguKXguLDguYPguJrguKrguKHguLHguITguKPguILguK3guIfguITguLjguJMgPExpbms+4LmA4Lij4Li14Lii4LiZ4Lij4Li54LmJ4LmA4Lie4Li04LmI4Lih4LmA4LiV4Li04Lih4Lie4Lij4LmJ4Lit4Lih4LiE4Liz4LmB4LiZ4Liw4LiZ4LizPC9MaW5rPi4iLCJNb3JlIGpvYnMgZm9yIHlvdSI6IuC4h+C4suC4meC5gOC4nuC4tOC5iOC4oeC5gOC4leC4tOC4oeC4quC4s+C4q+C4o+C4seC4muC4hOC4uOC4kyIsIlVwZGF0ZSB5b3VyIHByb2ZpbGUgb3Igc3RhcnQgc2VhcmNoaW5nIjoiPExpbms+4Lit4Lix4Lib4LmA4LiU4LiV4LmC4Lib4Lij4LmE4Lif4Lil4LmM4LiC4Lit4LiH4LiE4Li44LiTPC9MaW5rPiDguKvguKPguLfguK3guYDguKPguLTguYjguKHguITguYnguJnguKvguLLguIfguLLguJnguYDguJ7guLfguYjguK3guKPguLHguJrguJXguLPguYHguKvguJnguYjguIfguIfguLLguJnguYHguJnguLDguJnguLPguJfguLXguYjguITguLHguJTguKrguKPguKPguKHguLLguYDguJ7guLfguYjguK3guITguLjguJPguJfguLXguYjguJnguLXguYgifQ==!"),
        () => import(
          /* webpackChunkName: "th-TH-translations" */
          "./translations.json-th-TH-virtual.json!=!../../../../../../node_modules/.pnpm/virtual-resource-loader@1.0.1/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJSZWNvbW1lbmRlZCBqb2JzIjoi4LiV4Liz4LmB4Lir4LiZ4LmI4LiH4LiH4Liy4LiZ4LmB4LiZ4Liw4LiZ4Liz4Liq4Liz4Lir4Lij4Lix4Lia4LiE4Li44LiTIiwiQmFzZWQgb24geW91ciBwcm9maWxlLCBqb2Igdmlld3MgYW5kIGFwcGxpY2F0aW9ucy4gTGVhcm4gaG93Ijoi4LiC4Li24LmJ4LiZ4Lit4Lii4Li54LmI4LiB4Lix4Lia4LmC4Lib4Lij4LmE4Lif4Lil4LmMIOC4oeC4uOC4oeC4oeC4reC4h+C4h+C4suC4mSDguYHguKXguLDguYPguJrguKrguKHguLHguITguKPguILguK3guIfguITguLjguJMgPExpbms+4LmA4Lij4Li14Lii4LiZ4Lij4Li54LmJ4LmA4Lie4Li04LmI4Lih4LmA4LiV4Li04Lih4Lie4Lij4LmJ4Lit4Lih4LiE4Liz4LmB4LiZ4Liw4LiZ4LizPC9MaW5rPi4iLCJNb3JlIGpvYnMgZm9yIHlvdSI6IuC4h+C4suC4meC5gOC4nuC4tOC5iOC4oeC5gOC4leC4tOC4oeC4quC4s+C4q+C4o+C4seC4muC4hOC4uOC4kyIsIlVwZGF0ZSB5b3VyIHByb2ZpbGUgb3Igc3RhcnQgc2VhcmNoaW5nIjoiPExpbms+4Lit4Lix4Lib4LmA4LiU4LiV4LmC4Lib4Lij4LmE4Lif4Lil4LmM4LiC4Lit4LiH4LiE4Li44LiTPC9MaW5rPiDguKvguKPguLfguK3guYDguKPguLTguYjguKHguITguYnguJnguKvguLLguIfguLLguJnguYDguJ7guLfguYjguK3guKPguLHguJrguJXguLPguYHguKvguJnguYjguIfguIfguLLguJnguYHguJnguLDguJnguLPguJfguLXguYjguITguLHguJTguKrguKPguKPguKHguLLguYDguJ7guLfguYjguK3guITguLjguJPguJfguLXguYjguJnguLXguYgifQ==!"
        )
      ),
"en-PSEUDO": createLanguage(
        require.resolveWeak("./translations.json-en-PSEUDO-virtual.json!=!../../../../../../node_modules/.pnpm/virtual-resource-loader@1.0.1/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJSZWNvbW1lbmRlZCBqb2JzIjoiW8WY4bq94bq9w6fDtsO2bcyCbcyC4bq94bq94LiB4Li14LmJxozhur3hur3GjCDGksO2w7bFmSDDvcO9w7bDtseax5pdIiwiQmFzZWQgb24geW91ciBwcm9maWxlLCBqb2Igdmlld3MgYW5kIGFwcGxpY2F0aW9ucy4gTGVhcm4gaG93IjoiW+G6nsSDxIPEg8Wh4bq94bq94bq9xowgw7bDtsO24LiB4Li14LmJIMO9w73DvcO2w7bDtseax5rHmsWZIMalxZnDtsO2w7bGksOsw6zDrMaa4bq94bq94bq9LCDEtcO2w7bDtsOfIOG5vcOsw6zDrOG6veG6veG6vcW1xaEgxIPEg8SD4LiB4Li14LmJxowgxIPEg8SDxqXGpcaaw6zDrMOsw6fEg8SDxIPhua/DrMOsw6zDtsO2w7bguIHguLXguYnFoS4gxYHhur3hur3hur3Eg8SDxIPFmeC4geC4teC5iSDhuKnDtsO2w7bFtSA8TGluaz7FmeG6veG6veG6vcOnw7bDtsO2bcyCbcyC4bq94bq94bq94LiB4Li14LmJxozEg8SDxIPhua/DrMOsw6zDtsO2w7bguIHguLXguYnFoSDFtcO2w7bDtsWZxLc8L0xpbms+Ll0iLCJNb3JlIGpvYnMgZm9yIHlvdSI6IlvhuYLDtsO2xZnhur3hur0gxLXDtsO2w5/FoSDGksO2w7bFmSDDvcO9w7bDtseax5pdIiwiVXBkYXRlIHlvdXIgcHJvZmlsZSBvciBzdGFydCBzZWFyY2hpbmciOiJbPExpbms+x5nGpcaMxIPEg+G5r+G6veG6vSDDvcO9w7bDtseax5rFmSDGpcWZw7bDtsaSw6zDrMaa4bq94bq9PC9MaW5rPiDDtsO2w7bFmSDFoeG5r8SDxIPEg8WZ4bmvIMWh4bq94bq94bq9xIPEg8SDxZnDp+G4qcOsw6zDrOC4geC4teC5icSjIMaSw7bDtsO2xZkgxLXDtsO2w7bDn8WhIOG5r8O2w7bDtiDEo+G6veG6veG6veG5ryDGpeG6veG6veG6vcWZxaHDtsO2w7bguIHguLXguYnEg8SDxIPGmsOsw6zDrMWh4bq94bq94bq9xowgxLXDtsO2w7bDnyDFmeG6veG6veG6vcOnw7bDtsO2bcyCbcyC4bq94bq94bq94LiB4Li14LmJxozEg8SDxIPhua/DrMOsw6zDtsO2w7bguIHguLXguYnFoSDhuKnhur3hur3hur3FmeG6veG6veG6vS5dIn0=!"),
        () => import(
          /* webpackChunkName: "en-PSEUDO-translations" */
          "./translations.json-en-PSEUDO-virtual.json!=!../../../../../../node_modules/.pnpm/virtual-resource-loader@1.0.1/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJSZWNvbW1lbmRlZCBqb2JzIjoiW8WY4bq94bq9w6fDtsO2bcyCbcyC4bq94bq94LiB4Li14LmJxozhur3hur3GjCDGksO2w7bFmSDDvcO9w7bDtseax5pdIiwiQmFzZWQgb24geW91ciBwcm9maWxlLCBqb2Igdmlld3MgYW5kIGFwcGxpY2F0aW9ucy4gTGVhcm4gaG93IjoiW+G6nsSDxIPEg8Wh4bq94bq94bq9xowgw7bDtsO24LiB4Li14LmJIMO9w73DvcO2w7bDtseax5rHmsWZIMalxZnDtsO2w7bGksOsw6zDrMaa4bq94bq94bq9LCDEtcO2w7bDtsOfIOG5vcOsw6zDrOG6veG6veG6vcW1xaEgxIPEg8SD4LiB4Li14LmJxowgxIPEg8SDxqXGpcaaw6zDrMOsw6fEg8SDxIPhua/DrMOsw6zDtsO2w7bguIHguLXguYnFoS4gxYHhur3hur3hur3Eg8SDxIPFmeC4geC4teC5iSDhuKnDtsO2w7bFtSA8TGluaz7FmeG6veG6veG6vcOnw7bDtsO2bcyCbcyC4bq94bq94bq94LiB4Li14LmJxozEg8SDxIPhua/DrMOsw6zDtsO2w7bguIHguLXguYnFoSDFtcO2w7bDtsWZxLc8L0xpbms+Ll0iLCJNb3JlIGpvYnMgZm9yIHlvdSI6IlvhuYLDtsO2xZnhur3hur0gxLXDtsO2w5/FoSDGksO2w7bFmSDDvcO9w7bDtseax5pdIiwiVXBkYXRlIHlvdXIgcHJvZmlsZSBvciBzdGFydCBzZWFyY2hpbmciOiJbPExpbms+x5nGpcaMxIPEg+G5r+G6veG6vSDDvcO9w7bDtseax5rFmSDGpcWZw7bDtsaSw6zDrMaa4bq94bq9PC9MaW5rPiDDtsO2w7bFmSDFoeG5r8SDxIPEg8WZ4bmvIMWh4bq94bq94bq9xIPEg8SDxZnDp+G4qcOsw6zDrOC4geC4teC5icSjIMaSw7bDtsO2xZkgxLXDtsO2w7bDn8WhIOG5r8O2w7bDtiDEo+G6veG6veG6veG5ryDGpeG6veG6veG6vcWZxaHDtsO2w7bguIHguLXguYnEg8SDxIPGmsOsw6zDrMWh4bq94bq94bq9xowgxLXDtsO2w7bDnyDFmeG6veG6veG6vcOnw7bDtsO2bcyCbcyC4bq94bq94bq94LiB4Li14LmJxozEg8SDxIPhua/DrMOsw6zDtsO2w7bguIHguLXguYnFoSDhuKnhur3hur3hur3FmeG6veG6veG6vS5dIn0=!"
        )
      )
      });
  
      export { translations as default };
    