import type { SolMetadata } from '@seek/sol-js';
import { useTranslations } from '@vocab/react';
import { HiddenVisually } from 'braid-design-system';
import { memo, type ReactNode } from 'react';

import useSolMetadataReference from 'src/modules/hooks/useSolMetadataReference';
import SolImpressionTracker from 'src/modules/solTracker/SolImpressionTracker';

import DashboardItem from '../DashboardItem/DashboardItem';

import translations from './.vocab';

export interface RecJobItemJobType {
  id: string;
  title: string;
  advertiser: string;
  isNew?: boolean;
  order?: number;
  solMetadata: SolMetadata;
}

interface PropsType {
  order: number;
  job: RecJobItemJobType;
  seekerId?: number;
  batchId?: number;
  sessionId?: string;
}

const RecommendedJobItem = (props: PropsType) => {
  const { t } = useTranslations(translations);

  const { id, title, advertiser, isNew } = props.job;
  const translatedTitle = t('{title}. At {advertiser}', {
    title,
    advertiser,
    HiddenVisually: (children: ReactNode) => (
      <HiddenVisually>{children}</HiddenVisually>
    ),
  });

  const solMetadataReference = useSolMetadataReference({
    jobId: Number(id),
    displayType: 'loggedInHomeRecs',
  });

  return (
    <DashboardItem
      key={id}
      isNew={isNew}
      headingProps={{
        analytics: 'dashboard-recommended-job-click',
        linkContext: {
          jobId: id,
          linkPosition: `${props.order}`,
          linkSection: 'Recommended Jobs',
        },
        location: {
          pathname: `/job/${id}`,
          query: { pos: props.order, ref: 'recom-homepage' },
          hash: `sol=${solMetadataReference}`,
        },
      }}
      heading={title}
      screenReaderHeading={translatedTitle}
      subheading={advertiser}
    />
  );
};

const withSolImpressionTracker = (props: PropsType) => (
  <SolImpressionTracker
    jobId={props.job.id}
    solMetadata={props.job.solMetadata}
  >
    <RecommendedJobItem {...props} />
  </SolImpressionTracker>
);

export default memo(withSolImpressionTracker);
