
      import { createLanguage, createTranslationFile } from '@vocab/webpack/web';
      
    const translations = createTranslationFile({
      "en": createLanguage(
        require.resolveWeak("./translations.json-en-virtual.json!=!../../../../../../../node_modules/.pnpm/virtual-resource-loader@1.0.1/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJUaGUgUmVjb21tZW5kZWQgam9icyBzdW1tYXJ5IGlzIGN1cnJlbnRseSB1bmF2YWlsYWJsZSI6IlRoZSBSZWNvbW1lbmRlZCBqb2JzIHN1bW1hcnkgaXMgY3VycmVudGx5IHVuYXZhaWxhYmxlLiBQbGVhc2UgZ28gdG8gdGhlIDxMaW5rPlJlY29tbWVuZGVkIGpvYnMgcGFnZTwvTGluaz4uIiwiVGhlIFNhdmVkIGpvYnMgc3VtbWFyeSBpcyBjdXJyZW50bHkgdW5hdmFpbGFibGUiOiJUaGUgU2F2ZWQgam9icyBzdW1tYXJ5IGlzIGN1cnJlbnRseSB1bmF2YWlsYWJsZS4gUGxlYXNlIGdvIHRvIHRoZSA8TGluaz5TYXZlZCBqb2JzIHBhZ2U8L0xpbms+LiIsIlRoZSBTYXZlZCBzZWFyY2hlcyBzdW1tYXJ5IGlzIGN1cnJlbnRseSB1bmF2YWlsYWJsZSI6IlRoZSBTYXZlZCBzZWFyY2hlcyBzdW1tYXJ5IGlzIGN1cnJlbnRseSB1bmF2YWlsYWJsZS4gUGxlYXNlIGdvIHRvIHRoZSA8TGluaz5TYXZlZCBzZWFyY2hlcyBwYWdlPC9MaW5rPi4ifQ==!"),
        () => import(
          /* webpackChunkName: "en-translations" */
          "./translations.json-en-virtual.json!=!../../../../../../../node_modules/.pnpm/virtual-resource-loader@1.0.1/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJUaGUgUmVjb21tZW5kZWQgam9icyBzdW1tYXJ5IGlzIGN1cnJlbnRseSB1bmF2YWlsYWJsZSI6IlRoZSBSZWNvbW1lbmRlZCBqb2JzIHN1bW1hcnkgaXMgY3VycmVudGx5IHVuYXZhaWxhYmxlLiBQbGVhc2UgZ28gdG8gdGhlIDxMaW5rPlJlY29tbWVuZGVkIGpvYnMgcGFnZTwvTGluaz4uIiwiVGhlIFNhdmVkIGpvYnMgc3VtbWFyeSBpcyBjdXJyZW50bHkgdW5hdmFpbGFibGUiOiJUaGUgU2F2ZWQgam9icyBzdW1tYXJ5IGlzIGN1cnJlbnRseSB1bmF2YWlsYWJsZS4gUGxlYXNlIGdvIHRvIHRoZSA8TGluaz5TYXZlZCBqb2JzIHBhZ2U8L0xpbms+LiIsIlRoZSBTYXZlZCBzZWFyY2hlcyBzdW1tYXJ5IGlzIGN1cnJlbnRseSB1bmF2YWlsYWJsZSI6IlRoZSBTYXZlZCBzZWFyY2hlcyBzdW1tYXJ5IGlzIGN1cnJlbnRseSB1bmF2YWlsYWJsZS4gUGxlYXNlIGdvIHRvIHRoZSA8TGluaz5TYXZlZCBzZWFyY2hlcyBwYWdlPC9MaW5rPi4ifQ==!"
        )
      ),
"en-AU": createLanguage(
        require.resolveWeak("./translations.json-en-AU-virtual.json!=!../../../../../../../node_modules/.pnpm/virtual-resource-loader@1.0.1/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJUaGUgUmVjb21tZW5kZWQgam9icyBzdW1tYXJ5IGlzIGN1cnJlbnRseSB1bmF2YWlsYWJsZSI6IlRoZSBSZWNvbW1lbmRlZCBqb2JzIHN1bW1hcnkgaXMgY3VycmVudGx5IHVuYXZhaWxhYmxlLiBQbGVhc2UgZ28gdG8gdGhlIDxMaW5rPlJlY29tbWVuZGVkIGpvYnMgcGFnZTwvTGluaz4uIiwiVGhlIFNhdmVkIGpvYnMgc3VtbWFyeSBpcyBjdXJyZW50bHkgdW5hdmFpbGFibGUiOiJUaGUgU2F2ZWQgam9icyBzdW1tYXJ5IGlzIGN1cnJlbnRseSB1bmF2YWlsYWJsZS4gUGxlYXNlIGdvIHRvIHRoZSA8TGluaz5TYXZlZCBqb2JzIHBhZ2U8L0xpbms+LiIsIlRoZSBTYXZlZCBzZWFyY2hlcyBzdW1tYXJ5IGlzIGN1cnJlbnRseSB1bmF2YWlsYWJsZSI6IlRoZSBTYXZlZCBzZWFyY2hlcyBzdW1tYXJ5IGlzIGN1cnJlbnRseSB1bmF2YWlsYWJsZS4gUGxlYXNlIGdvIHRvIHRoZSA8TGluaz5TYXZlZCBzZWFyY2hlcyBwYWdlPC9MaW5rPi4ifQ==!"),
        () => import(
          /* webpackChunkName: "en-AU-translations" */
          "./translations.json-en-AU-virtual.json!=!../../../../../../../node_modules/.pnpm/virtual-resource-loader@1.0.1/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJUaGUgUmVjb21tZW5kZWQgam9icyBzdW1tYXJ5IGlzIGN1cnJlbnRseSB1bmF2YWlsYWJsZSI6IlRoZSBSZWNvbW1lbmRlZCBqb2JzIHN1bW1hcnkgaXMgY3VycmVudGx5IHVuYXZhaWxhYmxlLiBQbGVhc2UgZ28gdG8gdGhlIDxMaW5rPlJlY29tbWVuZGVkIGpvYnMgcGFnZTwvTGluaz4uIiwiVGhlIFNhdmVkIGpvYnMgc3VtbWFyeSBpcyBjdXJyZW50bHkgdW5hdmFpbGFibGUiOiJUaGUgU2F2ZWQgam9icyBzdW1tYXJ5IGlzIGN1cnJlbnRseSB1bmF2YWlsYWJsZS4gUGxlYXNlIGdvIHRvIHRoZSA8TGluaz5TYXZlZCBqb2JzIHBhZ2U8L0xpbms+LiIsIlRoZSBTYXZlZCBzZWFyY2hlcyBzdW1tYXJ5IGlzIGN1cnJlbnRseSB1bmF2YWlsYWJsZSI6IlRoZSBTYXZlZCBzZWFyY2hlcyBzdW1tYXJ5IGlzIGN1cnJlbnRseSB1bmF2YWlsYWJsZS4gUGxlYXNlIGdvIHRvIHRoZSA8TGluaz5TYXZlZCBzZWFyY2hlcyBwYWdlPC9MaW5rPi4ifQ==!"
        )
      ),
"en-NZ": createLanguage(
        require.resolveWeak("./translations.json-en-NZ-virtual.json!=!../../../../../../../node_modules/.pnpm/virtual-resource-loader@1.0.1/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJUaGUgUmVjb21tZW5kZWQgam9icyBzdW1tYXJ5IGlzIGN1cnJlbnRseSB1bmF2YWlsYWJsZSI6IlRoZSBSZWNvbW1lbmRlZCBqb2JzIHN1bW1hcnkgaXMgY3VycmVudGx5IHVuYXZhaWxhYmxlLiBQbGVhc2UgZ28gdG8gdGhlIDxMaW5rPlJlY29tbWVuZGVkIGpvYnMgcGFnZTwvTGluaz4uIiwiVGhlIFNhdmVkIGpvYnMgc3VtbWFyeSBpcyBjdXJyZW50bHkgdW5hdmFpbGFibGUiOiJUaGUgU2F2ZWQgam9icyBzdW1tYXJ5IGlzIGN1cnJlbnRseSB1bmF2YWlsYWJsZS4gUGxlYXNlIGdvIHRvIHRoZSA8TGluaz5TYXZlZCBqb2JzIHBhZ2U8L0xpbms+LiIsIlRoZSBTYXZlZCBzZWFyY2hlcyBzdW1tYXJ5IGlzIGN1cnJlbnRseSB1bmF2YWlsYWJsZSI6IlRoZSBTYXZlZCBzZWFyY2hlcyBzdW1tYXJ5IGlzIGN1cnJlbnRseSB1bmF2YWlsYWJsZS4gUGxlYXNlIGdvIHRvIHRoZSA8TGluaz5TYXZlZCBzZWFyY2hlcyBwYWdlPC9MaW5rPi4ifQ==!"),
        () => import(
          /* webpackChunkName: "en-NZ-translations" */
          "./translations.json-en-NZ-virtual.json!=!../../../../../../../node_modules/.pnpm/virtual-resource-loader@1.0.1/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJUaGUgUmVjb21tZW5kZWQgam9icyBzdW1tYXJ5IGlzIGN1cnJlbnRseSB1bmF2YWlsYWJsZSI6IlRoZSBSZWNvbW1lbmRlZCBqb2JzIHN1bW1hcnkgaXMgY3VycmVudGx5IHVuYXZhaWxhYmxlLiBQbGVhc2UgZ28gdG8gdGhlIDxMaW5rPlJlY29tbWVuZGVkIGpvYnMgcGFnZTwvTGluaz4uIiwiVGhlIFNhdmVkIGpvYnMgc3VtbWFyeSBpcyBjdXJyZW50bHkgdW5hdmFpbGFibGUiOiJUaGUgU2F2ZWQgam9icyBzdW1tYXJ5IGlzIGN1cnJlbnRseSB1bmF2YWlsYWJsZS4gUGxlYXNlIGdvIHRvIHRoZSA8TGluaz5TYXZlZCBqb2JzIHBhZ2U8L0xpbms+LiIsIlRoZSBTYXZlZCBzZWFyY2hlcyBzdW1tYXJ5IGlzIGN1cnJlbnRseSB1bmF2YWlsYWJsZSI6IlRoZSBTYXZlZCBzZWFyY2hlcyBzdW1tYXJ5IGlzIGN1cnJlbnRseSB1bmF2YWlsYWJsZS4gUGxlYXNlIGdvIHRvIHRoZSA8TGluaz5TYXZlZCBzZWFyY2hlcyBwYWdlPC9MaW5rPi4ifQ==!"
        )
      ),
"en-HK": createLanguage(
        require.resolveWeak("./translations.json-en-HK-virtual.json!=!../../../../../../../node_modules/.pnpm/virtual-resource-loader@1.0.1/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJUaGUgUmVjb21tZW5kZWQgam9icyBzdW1tYXJ5IGlzIGN1cnJlbnRseSB1bmF2YWlsYWJsZSI6IlRoZSBSZWNvbW1lbmRlZCBqb2JzIHN1bW1hcnkgaXMgY3VycmVudGx5IHVuYXZhaWxhYmxlLiBQbGVhc2UgZ28gdG8gdGhlIDxMaW5rPlJlY29tbWVuZGVkIGpvYnMgcGFnZTwvTGluaz4uIiwiVGhlIFNhdmVkIGpvYnMgc3VtbWFyeSBpcyBjdXJyZW50bHkgdW5hdmFpbGFibGUiOiJUaGUgU2F2ZWQgam9icyBzdW1tYXJ5IGlzIGN1cnJlbnRseSB1bmF2YWlsYWJsZS4gUGxlYXNlIGdvIHRvIHRoZSA8TGluaz5TYXZlZCBqb2JzIHBhZ2U8L0xpbms+LiIsIlRoZSBTYXZlZCBzZWFyY2hlcyBzdW1tYXJ5IGlzIGN1cnJlbnRseSB1bmF2YWlsYWJsZSI6IlRoZSBTYXZlZCBzZWFyY2hlcyBzdW1tYXJ5IGlzIGN1cnJlbnRseSB1bmF2YWlsYWJsZS4gUGxlYXNlIGdvIHRvIHRoZSA8TGluaz5TYXZlZCBzZWFyY2hlcyBwYWdlPC9MaW5rPi4ifQ==!"),
        () => import(
          /* webpackChunkName: "en-HK-translations" */
          "./translations.json-en-HK-virtual.json!=!../../../../../../../node_modules/.pnpm/virtual-resource-loader@1.0.1/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJUaGUgUmVjb21tZW5kZWQgam9icyBzdW1tYXJ5IGlzIGN1cnJlbnRseSB1bmF2YWlsYWJsZSI6IlRoZSBSZWNvbW1lbmRlZCBqb2JzIHN1bW1hcnkgaXMgY3VycmVudGx5IHVuYXZhaWxhYmxlLiBQbGVhc2UgZ28gdG8gdGhlIDxMaW5rPlJlY29tbWVuZGVkIGpvYnMgcGFnZTwvTGluaz4uIiwiVGhlIFNhdmVkIGpvYnMgc3VtbWFyeSBpcyBjdXJyZW50bHkgdW5hdmFpbGFibGUiOiJUaGUgU2F2ZWQgam9icyBzdW1tYXJ5IGlzIGN1cnJlbnRseSB1bmF2YWlsYWJsZS4gUGxlYXNlIGdvIHRvIHRoZSA8TGluaz5TYXZlZCBqb2JzIHBhZ2U8L0xpbms+LiIsIlRoZSBTYXZlZCBzZWFyY2hlcyBzdW1tYXJ5IGlzIGN1cnJlbnRseSB1bmF2YWlsYWJsZSI6IlRoZSBTYXZlZCBzZWFyY2hlcyBzdW1tYXJ5IGlzIGN1cnJlbnRseSB1bmF2YWlsYWJsZS4gUGxlYXNlIGdvIHRvIHRoZSA8TGluaz5TYXZlZCBzZWFyY2hlcyBwYWdlPC9MaW5rPi4ifQ==!"
        )
      ),
"en-TH": createLanguage(
        require.resolveWeak("./translations.json-en-TH-virtual.json!=!../../../../../../../node_modules/.pnpm/virtual-resource-loader@1.0.1/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJUaGUgUmVjb21tZW5kZWQgam9icyBzdW1tYXJ5IGlzIGN1cnJlbnRseSB1bmF2YWlsYWJsZSI6IlRoZSBSZWNvbW1lbmRlZCBqb2JzIHN1bW1hcnkgaXMgY3VycmVudGx5IHVuYXZhaWxhYmxlLiBQbGVhc2UgZ28gdG8gdGhlIDxMaW5rPlJlY29tbWVuZGVkIGpvYnMgcGFnZTwvTGluaz4uIiwiVGhlIFNhdmVkIGpvYnMgc3VtbWFyeSBpcyBjdXJyZW50bHkgdW5hdmFpbGFibGUiOiJUaGUgU2F2ZWQgam9icyBzdW1tYXJ5IGlzIGN1cnJlbnRseSB1bmF2YWlsYWJsZS4gUGxlYXNlIGdvIHRvIHRoZSA8TGluaz5TYXZlZCBqb2JzIHBhZ2U8L0xpbms+LiIsIlRoZSBTYXZlZCBzZWFyY2hlcyBzdW1tYXJ5IGlzIGN1cnJlbnRseSB1bmF2YWlsYWJsZSI6IlRoZSBTYXZlZCBzZWFyY2hlcyBzdW1tYXJ5IGlzIGN1cnJlbnRseSB1bmF2YWlsYWJsZS4gUGxlYXNlIGdvIHRvIHRoZSA8TGluaz5TYXZlZCBzZWFyY2hlcyBwYWdlPC9MaW5rPi4ifQ==!"),
        () => import(
          /* webpackChunkName: "en-TH-translations" */
          "./translations.json-en-TH-virtual.json!=!../../../../../../../node_modules/.pnpm/virtual-resource-loader@1.0.1/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJUaGUgUmVjb21tZW5kZWQgam9icyBzdW1tYXJ5IGlzIGN1cnJlbnRseSB1bmF2YWlsYWJsZSI6IlRoZSBSZWNvbW1lbmRlZCBqb2JzIHN1bW1hcnkgaXMgY3VycmVudGx5IHVuYXZhaWxhYmxlLiBQbGVhc2UgZ28gdG8gdGhlIDxMaW5rPlJlY29tbWVuZGVkIGpvYnMgcGFnZTwvTGluaz4uIiwiVGhlIFNhdmVkIGpvYnMgc3VtbWFyeSBpcyBjdXJyZW50bHkgdW5hdmFpbGFibGUiOiJUaGUgU2F2ZWQgam9icyBzdW1tYXJ5IGlzIGN1cnJlbnRseSB1bmF2YWlsYWJsZS4gUGxlYXNlIGdvIHRvIHRoZSA8TGluaz5TYXZlZCBqb2JzIHBhZ2U8L0xpbms+LiIsIlRoZSBTYXZlZCBzZWFyY2hlcyBzdW1tYXJ5IGlzIGN1cnJlbnRseSB1bmF2YWlsYWJsZSI6IlRoZSBTYXZlZCBzZWFyY2hlcyBzdW1tYXJ5IGlzIGN1cnJlbnRseSB1bmF2YWlsYWJsZS4gUGxlYXNlIGdvIHRvIHRoZSA8TGluaz5TYXZlZCBzZWFyY2hlcyBwYWdlPC9MaW5rPi4ifQ==!"
        )
      ),
"en-ID": createLanguage(
        require.resolveWeak("./translations.json-en-ID-virtual.json!=!../../../../../../../node_modules/.pnpm/virtual-resource-loader@1.0.1/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJUaGUgUmVjb21tZW5kZWQgam9icyBzdW1tYXJ5IGlzIGN1cnJlbnRseSB1bmF2YWlsYWJsZSI6IlRoZSBSZWNvbW1lbmRlZCBqb2JzIHN1bW1hcnkgaXMgY3VycmVudGx5IHVuYXZhaWxhYmxlLiBQbGVhc2UgZ28gdG8gdGhlIDxMaW5rPlJlY29tbWVuZGVkIGpvYnMgcGFnZTwvTGluaz4uIiwiVGhlIFNhdmVkIGpvYnMgc3VtbWFyeSBpcyBjdXJyZW50bHkgdW5hdmFpbGFibGUiOiJUaGUgU2F2ZWQgam9icyBzdW1tYXJ5IGlzIGN1cnJlbnRseSB1bmF2YWlsYWJsZS4gUGxlYXNlIGdvIHRvIHRoZSA8TGluaz5TYXZlZCBqb2JzIHBhZ2U8L0xpbms+LiIsIlRoZSBTYXZlZCBzZWFyY2hlcyBzdW1tYXJ5IGlzIGN1cnJlbnRseSB1bmF2YWlsYWJsZSI6IlRoZSBTYXZlZCBzZWFyY2hlcyBzdW1tYXJ5IGlzIGN1cnJlbnRseSB1bmF2YWlsYWJsZS4gUGxlYXNlIGdvIHRvIHRoZSA8TGluaz5TYXZlZCBzZWFyY2hlcyBwYWdlPC9MaW5rPi4ifQ==!"),
        () => import(
          /* webpackChunkName: "en-ID-translations" */
          "./translations.json-en-ID-virtual.json!=!../../../../../../../node_modules/.pnpm/virtual-resource-loader@1.0.1/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJUaGUgUmVjb21tZW5kZWQgam9icyBzdW1tYXJ5IGlzIGN1cnJlbnRseSB1bmF2YWlsYWJsZSI6IlRoZSBSZWNvbW1lbmRlZCBqb2JzIHN1bW1hcnkgaXMgY3VycmVudGx5IHVuYXZhaWxhYmxlLiBQbGVhc2UgZ28gdG8gdGhlIDxMaW5rPlJlY29tbWVuZGVkIGpvYnMgcGFnZTwvTGluaz4uIiwiVGhlIFNhdmVkIGpvYnMgc3VtbWFyeSBpcyBjdXJyZW50bHkgdW5hdmFpbGFibGUiOiJUaGUgU2F2ZWQgam9icyBzdW1tYXJ5IGlzIGN1cnJlbnRseSB1bmF2YWlsYWJsZS4gUGxlYXNlIGdvIHRvIHRoZSA8TGluaz5TYXZlZCBqb2JzIHBhZ2U8L0xpbms+LiIsIlRoZSBTYXZlZCBzZWFyY2hlcyBzdW1tYXJ5IGlzIGN1cnJlbnRseSB1bmF2YWlsYWJsZSI6IlRoZSBTYXZlZCBzZWFyY2hlcyBzdW1tYXJ5IGlzIGN1cnJlbnRseSB1bmF2YWlsYWJsZS4gUGxlYXNlIGdvIHRvIHRoZSA8TGluaz5TYXZlZCBzZWFyY2hlcyBwYWdlPC9MaW5rPi4ifQ==!"
        )
      ),
"en-SG": createLanguage(
        require.resolveWeak("./translations.json-en-SG-virtual.json!=!../../../../../../../node_modules/.pnpm/virtual-resource-loader@1.0.1/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJUaGUgUmVjb21tZW5kZWQgam9icyBzdW1tYXJ5IGlzIGN1cnJlbnRseSB1bmF2YWlsYWJsZSI6IlRoZSBSZWNvbW1lbmRlZCBqb2JzIHN1bW1hcnkgaXMgY3VycmVudGx5IHVuYXZhaWxhYmxlLiBQbGVhc2UgZ28gdG8gdGhlIDxMaW5rPlJlY29tbWVuZGVkIGpvYnMgcGFnZTwvTGluaz4uIiwiVGhlIFNhdmVkIGpvYnMgc3VtbWFyeSBpcyBjdXJyZW50bHkgdW5hdmFpbGFibGUiOiJUaGUgU2F2ZWQgam9icyBzdW1tYXJ5IGlzIGN1cnJlbnRseSB1bmF2YWlsYWJsZS4gUGxlYXNlIGdvIHRvIHRoZSA8TGluaz5TYXZlZCBqb2JzIHBhZ2U8L0xpbms+LiIsIlRoZSBTYXZlZCBzZWFyY2hlcyBzdW1tYXJ5IGlzIGN1cnJlbnRseSB1bmF2YWlsYWJsZSI6IlRoZSBTYXZlZCBzZWFyY2hlcyBzdW1tYXJ5IGlzIGN1cnJlbnRseSB1bmF2YWlsYWJsZS4gUGxlYXNlIGdvIHRvIHRoZSA8TGluaz5TYXZlZCBzZWFyY2hlcyBwYWdlPC9MaW5rPi4ifQ==!"),
        () => import(
          /* webpackChunkName: "en-SG-translations" */
          "./translations.json-en-SG-virtual.json!=!../../../../../../../node_modules/.pnpm/virtual-resource-loader@1.0.1/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJUaGUgUmVjb21tZW5kZWQgam9icyBzdW1tYXJ5IGlzIGN1cnJlbnRseSB1bmF2YWlsYWJsZSI6IlRoZSBSZWNvbW1lbmRlZCBqb2JzIHN1bW1hcnkgaXMgY3VycmVudGx5IHVuYXZhaWxhYmxlLiBQbGVhc2UgZ28gdG8gdGhlIDxMaW5rPlJlY29tbWVuZGVkIGpvYnMgcGFnZTwvTGluaz4uIiwiVGhlIFNhdmVkIGpvYnMgc3VtbWFyeSBpcyBjdXJyZW50bHkgdW5hdmFpbGFibGUiOiJUaGUgU2F2ZWQgam9icyBzdW1tYXJ5IGlzIGN1cnJlbnRseSB1bmF2YWlsYWJsZS4gUGxlYXNlIGdvIHRvIHRoZSA8TGluaz5TYXZlZCBqb2JzIHBhZ2U8L0xpbms+LiIsIlRoZSBTYXZlZCBzZWFyY2hlcyBzdW1tYXJ5IGlzIGN1cnJlbnRseSB1bmF2YWlsYWJsZSI6IlRoZSBTYXZlZCBzZWFyY2hlcyBzdW1tYXJ5IGlzIGN1cnJlbnRseSB1bmF2YWlsYWJsZS4gUGxlYXNlIGdvIHRvIHRoZSA8TGluaz5TYXZlZCBzZWFyY2hlcyBwYWdlPC9MaW5rPi4ifQ==!"
        )
      ),
"en-MY": createLanguage(
        require.resolveWeak("./translations.json-en-MY-virtual.json!=!../../../../../../../node_modules/.pnpm/virtual-resource-loader@1.0.1/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJUaGUgUmVjb21tZW5kZWQgam9icyBzdW1tYXJ5IGlzIGN1cnJlbnRseSB1bmF2YWlsYWJsZSI6IlRoZSBSZWNvbW1lbmRlZCBqb2JzIHN1bW1hcnkgaXMgY3VycmVudGx5IHVuYXZhaWxhYmxlLiBQbGVhc2UgZ28gdG8gdGhlIDxMaW5rPlJlY29tbWVuZGVkIGpvYnMgcGFnZTwvTGluaz4uIiwiVGhlIFNhdmVkIGpvYnMgc3VtbWFyeSBpcyBjdXJyZW50bHkgdW5hdmFpbGFibGUiOiJUaGUgU2F2ZWQgam9icyBzdW1tYXJ5IGlzIGN1cnJlbnRseSB1bmF2YWlsYWJsZS4gUGxlYXNlIGdvIHRvIHRoZSA8TGluaz5TYXZlZCBqb2JzIHBhZ2U8L0xpbms+LiIsIlRoZSBTYXZlZCBzZWFyY2hlcyBzdW1tYXJ5IGlzIGN1cnJlbnRseSB1bmF2YWlsYWJsZSI6IlRoZSBTYXZlZCBzZWFyY2hlcyBzdW1tYXJ5IGlzIGN1cnJlbnRseSB1bmF2YWlsYWJsZS4gUGxlYXNlIGdvIHRvIHRoZSA8TGluaz5TYXZlZCBzZWFyY2hlcyBwYWdlPC9MaW5rPi4ifQ==!"),
        () => import(
          /* webpackChunkName: "en-MY-translations" */
          "./translations.json-en-MY-virtual.json!=!../../../../../../../node_modules/.pnpm/virtual-resource-loader@1.0.1/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJUaGUgUmVjb21tZW5kZWQgam9icyBzdW1tYXJ5IGlzIGN1cnJlbnRseSB1bmF2YWlsYWJsZSI6IlRoZSBSZWNvbW1lbmRlZCBqb2JzIHN1bW1hcnkgaXMgY3VycmVudGx5IHVuYXZhaWxhYmxlLiBQbGVhc2UgZ28gdG8gdGhlIDxMaW5rPlJlY29tbWVuZGVkIGpvYnMgcGFnZTwvTGluaz4uIiwiVGhlIFNhdmVkIGpvYnMgc3VtbWFyeSBpcyBjdXJyZW50bHkgdW5hdmFpbGFibGUiOiJUaGUgU2F2ZWQgam9icyBzdW1tYXJ5IGlzIGN1cnJlbnRseSB1bmF2YWlsYWJsZS4gUGxlYXNlIGdvIHRvIHRoZSA8TGluaz5TYXZlZCBqb2JzIHBhZ2U8L0xpbms+LiIsIlRoZSBTYXZlZCBzZWFyY2hlcyBzdW1tYXJ5IGlzIGN1cnJlbnRseSB1bmF2YWlsYWJsZSI6IlRoZSBTYXZlZCBzZWFyY2hlcyBzdW1tYXJ5IGlzIGN1cnJlbnRseSB1bmF2YWlsYWJsZS4gUGxlYXNlIGdvIHRvIHRoZSA8TGluaz5TYXZlZCBzZWFyY2hlcyBwYWdlPC9MaW5rPi4ifQ==!"
        )
      ),
"en-PH": createLanguage(
        require.resolveWeak("./translations.json-en-PH-virtual.json!=!../../../../../../../node_modules/.pnpm/virtual-resource-loader@1.0.1/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJUaGUgUmVjb21tZW5kZWQgam9icyBzdW1tYXJ5IGlzIGN1cnJlbnRseSB1bmF2YWlsYWJsZSI6IlRoZSBSZWNvbW1lbmRlZCBqb2JzIHN1bW1hcnkgaXMgY3VycmVudGx5IHVuYXZhaWxhYmxlLiBQbGVhc2UgZ28gdG8gdGhlIDxMaW5rPlJlY29tbWVuZGVkIGpvYnMgcGFnZTwvTGluaz4uIiwiVGhlIFNhdmVkIGpvYnMgc3VtbWFyeSBpcyBjdXJyZW50bHkgdW5hdmFpbGFibGUiOiJUaGUgU2F2ZWQgam9icyBzdW1tYXJ5IGlzIGN1cnJlbnRseSB1bmF2YWlsYWJsZS4gUGxlYXNlIGdvIHRvIHRoZSA8TGluaz5TYXZlZCBqb2JzIHBhZ2U8L0xpbms+LiIsIlRoZSBTYXZlZCBzZWFyY2hlcyBzdW1tYXJ5IGlzIGN1cnJlbnRseSB1bmF2YWlsYWJsZSI6IlRoZSBTYXZlZCBzZWFyY2hlcyBzdW1tYXJ5IGlzIGN1cnJlbnRseSB1bmF2YWlsYWJsZS4gUGxlYXNlIGdvIHRvIHRoZSA8TGluaz5TYXZlZCBzZWFyY2hlcyBwYWdlPC9MaW5rPi4ifQ==!"),
        () => import(
          /* webpackChunkName: "en-PH-translations" */
          "./translations.json-en-PH-virtual.json!=!../../../../../../../node_modules/.pnpm/virtual-resource-loader@1.0.1/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJUaGUgUmVjb21tZW5kZWQgam9icyBzdW1tYXJ5IGlzIGN1cnJlbnRseSB1bmF2YWlsYWJsZSI6IlRoZSBSZWNvbW1lbmRlZCBqb2JzIHN1bW1hcnkgaXMgY3VycmVudGx5IHVuYXZhaWxhYmxlLiBQbGVhc2UgZ28gdG8gdGhlIDxMaW5rPlJlY29tbWVuZGVkIGpvYnMgcGFnZTwvTGluaz4uIiwiVGhlIFNhdmVkIGpvYnMgc3VtbWFyeSBpcyBjdXJyZW50bHkgdW5hdmFpbGFibGUiOiJUaGUgU2F2ZWQgam9icyBzdW1tYXJ5IGlzIGN1cnJlbnRseSB1bmF2YWlsYWJsZS4gUGxlYXNlIGdvIHRvIHRoZSA8TGluaz5TYXZlZCBqb2JzIHBhZ2U8L0xpbms+LiIsIlRoZSBTYXZlZCBzZWFyY2hlcyBzdW1tYXJ5IGlzIGN1cnJlbnRseSB1bmF2YWlsYWJsZSI6IlRoZSBTYXZlZCBzZWFyY2hlcyBzdW1tYXJ5IGlzIGN1cnJlbnRseSB1bmF2YWlsYWJsZS4gUGxlYXNlIGdvIHRvIHRoZSA8TGluaz5TYXZlZCBzZWFyY2hlcyBwYWdlPC9MaW5rPi4ifQ==!"
        )
      ),
"id": createLanguage(
        require.resolveWeak("./translations.json-id-virtual.json!=!../../../../../../../node_modules/.pnpm/virtual-resource-loader@1.0.1/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJUaGUgUmVjb21tZW5kZWQgam9icyBzdW1tYXJ5IGlzIGN1cnJlbnRseSB1bmF2YWlsYWJsZSI6IlRoZSBSZWNvbW1lbmRlZCBqb2JzIHN1bW1hcnkgaXMgY3VycmVudGx5IHVuYXZhaWxhYmxlLiBQbGVhc2UgZ28gdG8gdGhlIDxMaW5rPlJlY29tbWVuZGVkIGpvYnMgcGFnZTwvTGluaz4uIiwiVGhlIFNhdmVkIGpvYnMgc3VtbWFyeSBpcyBjdXJyZW50bHkgdW5hdmFpbGFibGUiOiJUaGUgU2F2ZWQgam9icyBzdW1tYXJ5IGlzIGN1cnJlbnRseSB1bmF2YWlsYWJsZS4gUGxlYXNlIGdvIHRvIHRoZSA8TGluaz5TYXZlZCBqb2JzIHBhZ2U8L0xpbms+LiIsIlRoZSBTYXZlZCBzZWFyY2hlcyBzdW1tYXJ5IGlzIGN1cnJlbnRseSB1bmF2YWlsYWJsZSI6IlRoZSBTYXZlZCBzZWFyY2hlcyBzdW1tYXJ5IGlzIGN1cnJlbnRseSB1bmF2YWlsYWJsZS4gUGxlYXNlIGdvIHRvIHRoZSA8TGluaz5TYXZlZCBzZWFyY2hlcyBwYWdlPC9MaW5rPi4ifQ==!"),
        () => import(
          /* webpackChunkName: "id-translations" */
          "./translations.json-id-virtual.json!=!../../../../../../../node_modules/.pnpm/virtual-resource-loader@1.0.1/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJUaGUgUmVjb21tZW5kZWQgam9icyBzdW1tYXJ5IGlzIGN1cnJlbnRseSB1bmF2YWlsYWJsZSI6IlRoZSBSZWNvbW1lbmRlZCBqb2JzIHN1bW1hcnkgaXMgY3VycmVudGx5IHVuYXZhaWxhYmxlLiBQbGVhc2UgZ28gdG8gdGhlIDxMaW5rPlJlY29tbWVuZGVkIGpvYnMgcGFnZTwvTGluaz4uIiwiVGhlIFNhdmVkIGpvYnMgc3VtbWFyeSBpcyBjdXJyZW50bHkgdW5hdmFpbGFibGUiOiJUaGUgU2F2ZWQgam9icyBzdW1tYXJ5IGlzIGN1cnJlbnRseSB1bmF2YWlsYWJsZS4gUGxlYXNlIGdvIHRvIHRoZSA8TGluaz5TYXZlZCBqb2JzIHBhZ2U8L0xpbms+LiIsIlRoZSBTYXZlZCBzZWFyY2hlcyBzdW1tYXJ5IGlzIGN1cnJlbnRseSB1bmF2YWlsYWJsZSI6IlRoZSBTYXZlZCBzZWFyY2hlcyBzdW1tYXJ5IGlzIGN1cnJlbnRseSB1bmF2YWlsYWJsZS4gUGxlYXNlIGdvIHRvIHRoZSA8TGluaz5TYXZlZCBzZWFyY2hlcyBwYWdlPC9MaW5rPi4ifQ==!"
        )
      ),
"th": createLanguage(
        require.resolveWeak("./translations.json-th-virtual.json!=!../../../../../../../node_modules/.pnpm/virtual-resource-loader@1.0.1/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJUaGUgUmVjb21tZW5kZWQgam9icyBzdW1tYXJ5IGlzIGN1cnJlbnRseSB1bmF2YWlsYWJsZSI6IlRoZSBSZWNvbW1lbmRlZCBqb2JzIHN1bW1hcnkgaXMgY3VycmVudGx5IHVuYXZhaWxhYmxlLiBQbGVhc2UgZ28gdG8gdGhlIDxMaW5rPlJlY29tbWVuZGVkIGpvYnMgcGFnZTwvTGluaz4uIiwiVGhlIFNhdmVkIGpvYnMgc3VtbWFyeSBpcyBjdXJyZW50bHkgdW5hdmFpbGFibGUiOiJUaGUgU2F2ZWQgam9icyBzdW1tYXJ5IGlzIGN1cnJlbnRseSB1bmF2YWlsYWJsZS4gUGxlYXNlIGdvIHRvIHRoZSA8TGluaz5TYXZlZCBqb2JzIHBhZ2U8L0xpbms+LiIsIlRoZSBTYXZlZCBzZWFyY2hlcyBzdW1tYXJ5IGlzIGN1cnJlbnRseSB1bmF2YWlsYWJsZSI6IlRoZSBTYXZlZCBzZWFyY2hlcyBzdW1tYXJ5IGlzIGN1cnJlbnRseSB1bmF2YWlsYWJsZS4gUGxlYXNlIGdvIHRvIHRoZSA8TGluaz5TYXZlZCBzZWFyY2hlcyBwYWdlPC9MaW5rPi4ifQ==!"),
        () => import(
          /* webpackChunkName: "th-translations" */
          "./translations.json-th-virtual.json!=!../../../../../../../node_modules/.pnpm/virtual-resource-loader@1.0.1/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJUaGUgUmVjb21tZW5kZWQgam9icyBzdW1tYXJ5IGlzIGN1cnJlbnRseSB1bmF2YWlsYWJsZSI6IlRoZSBSZWNvbW1lbmRlZCBqb2JzIHN1bW1hcnkgaXMgY3VycmVudGx5IHVuYXZhaWxhYmxlLiBQbGVhc2UgZ28gdG8gdGhlIDxMaW5rPlJlY29tbWVuZGVkIGpvYnMgcGFnZTwvTGluaz4uIiwiVGhlIFNhdmVkIGpvYnMgc3VtbWFyeSBpcyBjdXJyZW50bHkgdW5hdmFpbGFibGUiOiJUaGUgU2F2ZWQgam9icyBzdW1tYXJ5IGlzIGN1cnJlbnRseSB1bmF2YWlsYWJsZS4gUGxlYXNlIGdvIHRvIHRoZSA8TGluaz5TYXZlZCBqb2JzIHBhZ2U8L0xpbms+LiIsIlRoZSBTYXZlZCBzZWFyY2hlcyBzdW1tYXJ5IGlzIGN1cnJlbnRseSB1bmF2YWlsYWJsZSI6IlRoZSBTYXZlZCBzZWFyY2hlcyBzdW1tYXJ5IGlzIGN1cnJlbnRseSB1bmF2YWlsYWJsZS4gUGxlYXNlIGdvIHRvIHRoZSA8TGluaz5TYXZlZCBzZWFyY2hlcyBwYWdlPC9MaW5rPi4ifQ==!"
        )
      ),
"id-ID": createLanguage(
        require.resolveWeak("./translations.json-id-ID-virtual.json!=!../../../../../../../node_modules/.pnpm/virtual-resource-loader@1.0.1/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJUaGUgUmVjb21tZW5kZWQgam9icyBzdW1tYXJ5IGlzIGN1cnJlbnRseSB1bmF2YWlsYWJsZSI6IlJpbmdrYXNhbiBSZWtvbWVuZGFzaSBsb3dvbmdhbiBrZXJqYSBzYWF0IGluaSB0aWRhayB0ZXJzZWRpYS4gU2lsYWthbiBrdW5qdW5naSA8TGluaz5oYWxhbWFuIFJla29tZW5kYXNpIGxvd29uZ2FuIGtlcmphPC9MaW5rPi4iLCJUaGUgU2F2ZWQgam9icyBzdW1tYXJ5IGlzIGN1cnJlbnRseSB1bmF2YWlsYWJsZSI6IlJpbmdrYXNhbiBMb3dvbmdhbiBrZXJqYSB0ZXJzaW1wYW4gc2FhdCBpbmkgdGlkYWsgdGVyc2VkaWEuIFNpbGFrYW4ga3VuanVuZ2kgPExpbms+aGFsYW1hbiBMb3dvbmdhbiBrZXJqYSB0ZXJzaW1wYW48L0xpbms+LiIsIlRoZSBTYXZlZCBzZWFyY2hlcyBzdW1tYXJ5IGlzIGN1cnJlbnRseSB1bmF2YWlsYWJsZSI6IlJpbmdrYXNhbiBQZW5jYXJpYW4gdGVyc2ltcGFuIHNhYXQgaW5pIHRpZGFrIHRlcnNlZGlhLiBTaWxha2FuIGt1bmp1bmdpIDxMaW5rPmhhbGFtYW4gUGVuY2FyaWFuIHRlcnNpbXBhbjwvTGluaz4uIn0=!"),
        () => import(
          /* webpackChunkName: "id-ID-translations" */
          "./translations.json-id-ID-virtual.json!=!../../../../../../../node_modules/.pnpm/virtual-resource-loader@1.0.1/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJUaGUgUmVjb21tZW5kZWQgam9icyBzdW1tYXJ5IGlzIGN1cnJlbnRseSB1bmF2YWlsYWJsZSI6IlJpbmdrYXNhbiBSZWtvbWVuZGFzaSBsb3dvbmdhbiBrZXJqYSBzYWF0IGluaSB0aWRhayB0ZXJzZWRpYS4gU2lsYWthbiBrdW5qdW5naSA8TGluaz5oYWxhbWFuIFJla29tZW5kYXNpIGxvd29uZ2FuIGtlcmphPC9MaW5rPi4iLCJUaGUgU2F2ZWQgam9icyBzdW1tYXJ5IGlzIGN1cnJlbnRseSB1bmF2YWlsYWJsZSI6IlJpbmdrYXNhbiBMb3dvbmdhbiBrZXJqYSB0ZXJzaW1wYW4gc2FhdCBpbmkgdGlkYWsgdGVyc2VkaWEuIFNpbGFrYW4ga3VuanVuZ2kgPExpbms+aGFsYW1hbiBMb3dvbmdhbiBrZXJqYSB0ZXJzaW1wYW48L0xpbms+LiIsIlRoZSBTYXZlZCBzZWFyY2hlcyBzdW1tYXJ5IGlzIGN1cnJlbnRseSB1bmF2YWlsYWJsZSI6IlJpbmdrYXNhbiBQZW5jYXJpYW4gdGVyc2ltcGFuIHNhYXQgaW5pIHRpZGFrIHRlcnNlZGlhLiBTaWxha2FuIGt1bmp1bmdpIDxMaW5rPmhhbGFtYW4gUGVuY2FyaWFuIHRlcnNpbXBhbjwvTGluaz4uIn0=!"
        )
      ),
"th-TH": createLanguage(
        require.resolveWeak("./translations.json-th-TH-virtual.json!=!../../../../../../../node_modules/.pnpm/virtual-resource-loader@1.0.1/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJUaGUgUmVjb21tZW5kZWQgam9icyBzdW1tYXJ5IGlzIGN1cnJlbnRseSB1bmF2YWlsYWJsZSI6IuC4ouC4seC4h+C5hOC4oeC5iOC4oeC4teC4quC4o+C4uOC4m+C4h+C4suC4meC5geC4meC4sOC4meC4s+C5g+C4meC4guC4k+C4sOC4meC4teC5iSDguYLguJvguKPguJTguYDguILguYnguLLguYTguJvguJfguLXguYg8TGluaz7guKvguJnguYnguLLguYDguJ7guIjguIfguLLguJnguYHguJnguLDguJnguLM8L0xpbms+IiwiVGhlIFNhdmVkIGpvYnMgc3VtbWFyeSBpcyBjdXJyZW50bHkgdW5hdmFpbGFibGUiOiLguKLguLHguIfguYTguKHguYjguKHguLXguKrguKPguLjguJvguJrguLHguJnguJfguLbguIHguIfguLLguJnguYPguJnguILguJPguLDguJnguLXguYkg4LmC4Lib4Lij4LiU4LmA4LiC4LmJ4Liy4LmE4Lib4LiX4Li14LmIPExpbms+4Lir4LiZ4LmJ4Liy4LmA4Lie4LiI4Lia4Lix4LiZ4LiX4Li24LiB4LiH4Liy4LiZPC9MaW5rPiIsIlRoZSBTYXZlZCBzZWFyY2hlcyBzdW1tYXJ5IGlzIGN1cnJlbnRseSB1bmF2YWlsYWJsZSI6IuC4ouC4seC4h+C5hOC4oeC5iOC4oeC4teC4quC4o+C4uOC4m+C4muC4seC4meC4l+C4tuC4geC4geC4suC4o+C4hOC5ieC4meC4q+C4suC5g+C4meC4guC4k+C4sOC4meC4teC5iSDguYLguJvguKPguJTguYDguILguYnguLLguYTguJvguJfguLXguYg8TGluaz7guKvguJnguYnguLLguYDguJ7guIjguJrguLHguJnguJfguLbguIHguIHguLLguKPguITguYnguJnguKvguLI8L0xpbms+In0=!"),
        () => import(
          /* webpackChunkName: "th-TH-translations" */
          "./translations.json-th-TH-virtual.json!=!../../../../../../../node_modules/.pnpm/virtual-resource-loader@1.0.1/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJUaGUgUmVjb21tZW5kZWQgam9icyBzdW1tYXJ5IGlzIGN1cnJlbnRseSB1bmF2YWlsYWJsZSI6IuC4ouC4seC4h+C5hOC4oeC5iOC4oeC4teC4quC4o+C4uOC4m+C4h+C4suC4meC5geC4meC4sOC4meC4s+C5g+C4meC4guC4k+C4sOC4meC4teC5iSDguYLguJvguKPguJTguYDguILguYnguLLguYTguJvguJfguLXguYg8TGluaz7guKvguJnguYnguLLguYDguJ7guIjguIfguLLguJnguYHguJnguLDguJnguLM8L0xpbms+IiwiVGhlIFNhdmVkIGpvYnMgc3VtbWFyeSBpcyBjdXJyZW50bHkgdW5hdmFpbGFibGUiOiLguKLguLHguIfguYTguKHguYjguKHguLXguKrguKPguLjguJvguJrguLHguJnguJfguLbguIHguIfguLLguJnguYPguJnguILguJPguLDguJnguLXguYkg4LmC4Lib4Lij4LiU4LmA4LiC4LmJ4Liy4LmE4Lib4LiX4Li14LmIPExpbms+4Lir4LiZ4LmJ4Liy4LmA4Lie4LiI4Lia4Lix4LiZ4LiX4Li24LiB4LiH4Liy4LiZPC9MaW5rPiIsIlRoZSBTYXZlZCBzZWFyY2hlcyBzdW1tYXJ5IGlzIGN1cnJlbnRseSB1bmF2YWlsYWJsZSI6IuC4ouC4seC4h+C5hOC4oeC5iOC4oeC4teC4quC4o+C4uOC4m+C4muC4seC4meC4l+C4tuC4geC4geC4suC4o+C4hOC5ieC4meC4q+C4suC5g+C4meC4guC4k+C4sOC4meC4teC5iSDguYLguJvguKPguJTguYDguILguYnguLLguYTguJvguJfguLXguYg8TGluaz7guKvguJnguYnguLLguYDguJ7guIjguJrguLHguJnguJfguLbguIHguIHguLLguKPguITguYnguJnguKvguLI8L0xpbms+In0=!"
        )
      ),
"en-PSEUDO": createLanguage(
        require.resolveWeak("./translations.json-en-PSEUDO-virtual.json!=!../../../../../../../node_modules/.pnpm/virtual-resource-loader@1.0.1/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJUaGUgUmVjb21tZW5kZWQgam9icyBzdW1tYXJ5IGlzIGN1cnJlbnRseSB1bmF2YWlsYWJsZSI6Ilvhua7huKnhur3hur3hur0gxZjhur3hur3hur3Dp8O2w7bDtm3Mgm3MguG6veG6veG6veC4geC4teC5icaM4bq94bq94bq9xowgxLXDtsO2w7bDn8WhIMWhx5rHmseabcyCbcyCxIPEg8SDxZnDvcO9w70gw6zDrMOsxaEgw6fHmseax5rFmcWZ4bq94bq94bq94LiB4Li14LmJ4bmvxprDvcO9w70gx5rHmsea4LiB4Li14LmJxIPEg8SD4bm9xIPEg8SDw6zDrMOsxprEg8SDxIPDn8aa4bq94bq94bq9LiDGpMaa4bq94bq94bq9xIPEg8SDxaHhur3hur3hur0gxKPDtsO2w7Yg4bmvw7bDtsO2IOG5r+G4qeG6veG6veG6vSA8TGluaz7FmOG6veG6veG6vcOnw7bDtsO2bcyCbcyC4bq94bq94bq94LiB4Li14LmJxozhur3hur3hur3GjCDEtcO2w7bDtsOfxaEgxqXEg8SDxIPEo+G6veG6veG6vTwvTGluaz4uXSIsIlRoZSBTYXZlZCBqb2JzIHN1bW1hcnkgaXMgY3VycmVudGx5IHVuYXZhaWxhYmxlIjoiW+G5ruG4qeG6veG6veG6vSDFoMSDxIPEg+G5veG6veG6veG6vcaMIMS1w7bDtsO2w5/FoSDFoceax5rHmm3Mgm3MgsSDxIPEg8WZw73DvcO9IMOsw6zDrMWhIMOnx5rHmseaxZnFmeG6veG6veG6veC4geC4teC5ieG5r8aaw73DvcO9IMeax5rHmuC4geC4teC5icSDxIPEg+G5vcSDxIPEg8Osw6zDrMaaxIPEg8SDw5/GmuG6veG6veG6vS4gxqTGmuG6veG6veG6vcSDxIPEg8Wh4bq94bq94bq9IMSjw7bDtsO2IOG5r8O2w7bDtiDhua/huKnhur3hur3hur0gPExpbms+xaDEg8SDxIPhub3hur3hur3hur3GjCDEtcO2w7bDtsOfxaEgxqXEg8SDxIPEo+G6veG6veG6vTwvTGluaz4uXSIsIlRoZSBTYXZlZCBzZWFyY2hlcyBzdW1tYXJ5IGlzIGN1cnJlbnRseSB1bmF2YWlsYWJsZSI6Ilvhua7huKnhur3hur3hur0gxaDEg8SDxIPhub3hur3hur3hur3GjCDFoeG6veG6veG6vcSDxIPEg8WZw6fhuKnhur3hur3hur3FoSDFoceax5rHmm3Mgm3MgsSDxIPEg8WZw73DvcO9IMOsw6zDrMWhIMOnx5rHmseaxZnFmeG6veG6veG6veC4geC4teC5ieG5r8aaw73DvcO9IMeax5rHmuC4geC4teC5icSDxIPEg+G5vcSDxIPEg8Osw6zDrMaaxIPEg8SDw5/GmuG6veG6veG6vS4gxqTGmuG6veG6veG6vcSDxIPEg8Wh4bq94bq94bq9IMSjw7bDtsO2IOG5r8O2w7bDtiDhua/huKnhur3hur3hur0gPExpbms+xaDEg8SDxIPhub3hur3hur3hur3GjCDFoeG6veG6veG6vcSDxIPEg8WZw6fhuKnhur3hur3hur3FoSDGpcSDxIPEg8Sj4bq94bq94bq9PC9MaW5rPi5dIn0=!"),
        () => import(
          /* webpackChunkName: "en-PSEUDO-translations" */
          "./translations.json-en-PSEUDO-virtual.json!=!../../../../../../../node_modules/.pnpm/virtual-resource-loader@1.0.1/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJUaGUgUmVjb21tZW5kZWQgam9icyBzdW1tYXJ5IGlzIGN1cnJlbnRseSB1bmF2YWlsYWJsZSI6Ilvhua7huKnhur3hur3hur0gxZjhur3hur3hur3Dp8O2w7bDtm3Mgm3MguG6veG6veG6veC4geC4teC5icaM4bq94bq94bq9xowgxLXDtsO2w7bDn8WhIMWhx5rHmseabcyCbcyCxIPEg8SDxZnDvcO9w70gw6zDrMOsxaEgw6fHmseax5rFmcWZ4bq94bq94bq94LiB4Li14LmJ4bmvxprDvcO9w70gx5rHmsea4LiB4Li14LmJxIPEg8SD4bm9xIPEg8SDw6zDrMOsxprEg8SDxIPDn8aa4bq94bq94bq9LiDGpMaa4bq94bq94bq9xIPEg8SDxaHhur3hur3hur0gxKPDtsO2w7Yg4bmvw7bDtsO2IOG5r+G4qeG6veG6veG6vSA8TGluaz7FmOG6veG6veG6vcOnw7bDtsO2bcyCbcyC4bq94bq94bq94LiB4Li14LmJxozhur3hur3hur3GjCDEtcO2w7bDtsOfxaEgxqXEg8SDxIPEo+G6veG6veG6vTwvTGluaz4uXSIsIlRoZSBTYXZlZCBqb2JzIHN1bW1hcnkgaXMgY3VycmVudGx5IHVuYXZhaWxhYmxlIjoiW+G5ruG4qeG6veG6veG6vSDFoMSDxIPEg+G5veG6veG6veG6vcaMIMS1w7bDtsO2w5/FoSDFoceax5rHmm3Mgm3MgsSDxIPEg8WZw73DvcO9IMOsw6zDrMWhIMOnx5rHmseaxZnFmeG6veG6veG6veC4geC4teC5ieG5r8aaw73DvcO9IMeax5rHmuC4geC4teC5icSDxIPEg+G5vcSDxIPEg8Osw6zDrMaaxIPEg8SDw5/GmuG6veG6veG6vS4gxqTGmuG6veG6veG6vcSDxIPEg8Wh4bq94bq94bq9IMSjw7bDtsO2IOG5r8O2w7bDtiDhua/huKnhur3hur3hur0gPExpbms+xaDEg8SDxIPhub3hur3hur3hur3GjCDEtcO2w7bDtsOfxaEgxqXEg8SDxIPEo+G6veG6veG6vTwvTGluaz4uXSIsIlRoZSBTYXZlZCBzZWFyY2hlcyBzdW1tYXJ5IGlzIGN1cnJlbnRseSB1bmF2YWlsYWJsZSI6Ilvhua7huKnhur3hur3hur0gxaDEg8SDxIPhub3hur3hur3hur3GjCDFoeG6veG6veG6vcSDxIPEg8WZw6fhuKnhur3hur3hur3FoSDFoceax5rHmm3Mgm3MgsSDxIPEg8WZw73DvcO9IMOsw6zDrMWhIMOnx5rHmseaxZnFmeG6veG6veG6veC4geC4teC5ieG5r8aaw73DvcO9IMeax5rHmuC4geC4teC5icSDxIPEg+G5vcSDxIPEg8Osw6zDrMaaxIPEg8SDw5/GmuG6veG6veG6vS4gxqTGmuG6veG6veG6vcSDxIPEg8Wh4bq94bq94bq9IMSjw7bDtsO2IOG5r8O2w7bDtiDhua/huKnhur3hur3hur0gPExpbms+xaDEg8SDxIPhub3hur3hur3hur3GjCDFoeG6veG6veG6vcSDxIPEg8WZw6fhuKnhur3hur3hur3FoSDGpcSDxIPEg8Sj4bq94bq94bq9PC9MaW5rPi5dIn0=!"
        )
      )
      });
  
      export { translations as default };
    