
      import { createLanguage, createTranslationFile } from '@vocab/webpack/web';
      
    const translations = createTranslationFile({
      "en": createLanguage(
        require.resolveWeak("./translations.json-en-virtual.json!=!../../../../../../../node_modules/.pnpm/virtual-resource-loader@1.0.1/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJOZXciOiJOZXciLCJ7Y291bnR9IG5ldyI6Intjb3VudH0gbmV3IiwiTmV3IHRvIHlvdSI6Ik5ldyB0byB5b3UiLCJ7Y291bnR9IG5ldyB0byB5b3UiOiJ7Y291bnR9IG5ldyB0byB5b3UiLCJFeHBpcmVkIjoiRXhwaXJlZCIsIkV4cGlyZWQgam9iIjoiRXhwaXJlZCA8SGlkZGVuVmlzdWFsbHk+am9iPC9IaWRkZW5WaXN1YWxseT4ifQ==!"),
        () => import(
          /* webpackChunkName: "en-translations" */
          "./translations.json-en-virtual.json!=!../../../../../../../node_modules/.pnpm/virtual-resource-loader@1.0.1/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJOZXciOiJOZXciLCJ7Y291bnR9IG5ldyI6Intjb3VudH0gbmV3IiwiTmV3IHRvIHlvdSI6Ik5ldyB0byB5b3UiLCJ7Y291bnR9IG5ldyB0byB5b3UiOiJ7Y291bnR9IG5ldyB0byB5b3UiLCJFeHBpcmVkIjoiRXhwaXJlZCIsIkV4cGlyZWQgam9iIjoiRXhwaXJlZCA8SGlkZGVuVmlzdWFsbHk+am9iPC9IaWRkZW5WaXN1YWxseT4ifQ==!"
        )
      ),
"en-AU": createLanguage(
        require.resolveWeak("./translations.json-en-AU-virtual.json!=!../../../../../../../node_modules/.pnpm/virtual-resource-loader@1.0.1/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJOZXciOiJOZXciLCJ7Y291bnR9IG5ldyI6Intjb3VudH0gbmV3IiwiTmV3IHRvIHlvdSI6Ik5ldyB0byB5b3UiLCJ7Y291bnR9IG5ldyB0byB5b3UiOiJ7Y291bnR9IG5ldyB0byB5b3UiLCJFeHBpcmVkIjoiRXhwaXJlZCIsIkV4cGlyZWQgam9iIjoiRXhwaXJlZCA8SGlkZGVuVmlzdWFsbHk+am9iPC9IaWRkZW5WaXN1YWxseT4ifQ==!"),
        () => import(
          /* webpackChunkName: "en-AU-translations" */
          "./translations.json-en-AU-virtual.json!=!../../../../../../../node_modules/.pnpm/virtual-resource-loader@1.0.1/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJOZXciOiJOZXciLCJ7Y291bnR9IG5ldyI6Intjb3VudH0gbmV3IiwiTmV3IHRvIHlvdSI6Ik5ldyB0byB5b3UiLCJ7Y291bnR9IG5ldyB0byB5b3UiOiJ7Y291bnR9IG5ldyB0byB5b3UiLCJFeHBpcmVkIjoiRXhwaXJlZCIsIkV4cGlyZWQgam9iIjoiRXhwaXJlZCA8SGlkZGVuVmlzdWFsbHk+am9iPC9IaWRkZW5WaXN1YWxseT4ifQ==!"
        )
      ),
"en-NZ": createLanguage(
        require.resolveWeak("./translations.json-en-NZ-virtual.json!=!../../../../../../../node_modules/.pnpm/virtual-resource-loader@1.0.1/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJOZXciOiJOZXciLCJ7Y291bnR9IG5ldyI6Intjb3VudH0gbmV3IiwiTmV3IHRvIHlvdSI6Ik5ldyB0byB5b3UiLCJ7Y291bnR9IG5ldyB0byB5b3UiOiJ7Y291bnR9IG5ldyB0byB5b3UiLCJFeHBpcmVkIjoiRXhwaXJlZCIsIkV4cGlyZWQgam9iIjoiRXhwaXJlZCA8SGlkZGVuVmlzdWFsbHk+am9iPC9IaWRkZW5WaXN1YWxseT4ifQ==!"),
        () => import(
          /* webpackChunkName: "en-NZ-translations" */
          "./translations.json-en-NZ-virtual.json!=!../../../../../../../node_modules/.pnpm/virtual-resource-loader@1.0.1/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJOZXciOiJOZXciLCJ7Y291bnR9IG5ldyI6Intjb3VudH0gbmV3IiwiTmV3IHRvIHlvdSI6Ik5ldyB0byB5b3UiLCJ7Y291bnR9IG5ldyB0byB5b3UiOiJ7Y291bnR9IG5ldyB0byB5b3UiLCJFeHBpcmVkIjoiRXhwaXJlZCIsIkV4cGlyZWQgam9iIjoiRXhwaXJlZCA8SGlkZGVuVmlzdWFsbHk+am9iPC9IaWRkZW5WaXN1YWxseT4ifQ==!"
        )
      ),
"en-HK": createLanguage(
        require.resolveWeak("./translations.json-en-HK-virtual.json!=!../../../../../../../node_modules/.pnpm/virtual-resource-loader@1.0.1/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJOZXciOiJOZXciLCJ7Y291bnR9IG5ldyI6Intjb3VudH0gbmV3IiwiTmV3IHRvIHlvdSI6Ik5ldyB0byB5b3UiLCJ7Y291bnR9IG5ldyB0byB5b3UiOiJ7Y291bnR9IG5ldyB0byB5b3UiLCJFeHBpcmVkIjoiRXhwaXJlZCIsIkV4cGlyZWQgam9iIjoiRXhwaXJlZCA8SGlkZGVuVmlzdWFsbHk+am9iPC9IaWRkZW5WaXN1YWxseT4ifQ==!"),
        () => import(
          /* webpackChunkName: "en-HK-translations" */
          "./translations.json-en-HK-virtual.json!=!../../../../../../../node_modules/.pnpm/virtual-resource-loader@1.0.1/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJOZXciOiJOZXciLCJ7Y291bnR9IG5ldyI6Intjb3VudH0gbmV3IiwiTmV3IHRvIHlvdSI6Ik5ldyB0byB5b3UiLCJ7Y291bnR9IG5ldyB0byB5b3UiOiJ7Y291bnR9IG5ldyB0byB5b3UiLCJFeHBpcmVkIjoiRXhwaXJlZCIsIkV4cGlyZWQgam9iIjoiRXhwaXJlZCA8SGlkZGVuVmlzdWFsbHk+am9iPC9IaWRkZW5WaXN1YWxseT4ifQ==!"
        )
      ),
"en-TH": createLanguage(
        require.resolveWeak("./translations.json-en-TH-virtual.json!=!../../../../../../../node_modules/.pnpm/virtual-resource-loader@1.0.1/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJOZXciOiJOZXciLCJ7Y291bnR9IG5ldyI6Intjb3VudH0gbmV3IiwiTmV3IHRvIHlvdSI6Ik5ldyB0byB5b3UiLCJ7Y291bnR9IG5ldyB0byB5b3UiOiJ7Y291bnR9IG5ldyB0byB5b3UiLCJFeHBpcmVkIjoiRXhwaXJlZCIsIkV4cGlyZWQgam9iIjoiRXhwaXJlZCA8SGlkZGVuVmlzdWFsbHk+am9iPC9IaWRkZW5WaXN1YWxseT4ifQ==!"),
        () => import(
          /* webpackChunkName: "en-TH-translations" */
          "./translations.json-en-TH-virtual.json!=!../../../../../../../node_modules/.pnpm/virtual-resource-loader@1.0.1/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJOZXciOiJOZXciLCJ7Y291bnR9IG5ldyI6Intjb3VudH0gbmV3IiwiTmV3IHRvIHlvdSI6Ik5ldyB0byB5b3UiLCJ7Y291bnR9IG5ldyB0byB5b3UiOiJ7Y291bnR9IG5ldyB0byB5b3UiLCJFeHBpcmVkIjoiRXhwaXJlZCIsIkV4cGlyZWQgam9iIjoiRXhwaXJlZCA8SGlkZGVuVmlzdWFsbHk+am9iPC9IaWRkZW5WaXN1YWxseT4ifQ==!"
        )
      ),
"en-ID": createLanguage(
        require.resolveWeak("./translations.json-en-ID-virtual.json!=!../../../../../../../node_modules/.pnpm/virtual-resource-loader@1.0.1/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJOZXciOiJOZXciLCJ7Y291bnR9IG5ldyI6Intjb3VudH0gbmV3IiwiTmV3IHRvIHlvdSI6Ik5ldyB0byB5b3UiLCJ7Y291bnR9IG5ldyB0byB5b3UiOiJ7Y291bnR9IG5ldyB0byB5b3UiLCJFeHBpcmVkIjoiRXhwaXJlZCIsIkV4cGlyZWQgam9iIjoiRXhwaXJlZCA8SGlkZGVuVmlzdWFsbHk+am9iPC9IaWRkZW5WaXN1YWxseT4ifQ==!"),
        () => import(
          /* webpackChunkName: "en-ID-translations" */
          "./translations.json-en-ID-virtual.json!=!../../../../../../../node_modules/.pnpm/virtual-resource-loader@1.0.1/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJOZXciOiJOZXciLCJ7Y291bnR9IG5ldyI6Intjb3VudH0gbmV3IiwiTmV3IHRvIHlvdSI6Ik5ldyB0byB5b3UiLCJ7Y291bnR9IG5ldyB0byB5b3UiOiJ7Y291bnR9IG5ldyB0byB5b3UiLCJFeHBpcmVkIjoiRXhwaXJlZCIsIkV4cGlyZWQgam9iIjoiRXhwaXJlZCA8SGlkZGVuVmlzdWFsbHk+am9iPC9IaWRkZW5WaXN1YWxseT4ifQ==!"
        )
      ),
"en-SG": createLanguage(
        require.resolveWeak("./translations.json-en-SG-virtual.json!=!../../../../../../../node_modules/.pnpm/virtual-resource-loader@1.0.1/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJOZXciOiJOZXciLCJ7Y291bnR9IG5ldyI6Intjb3VudH0gbmV3IiwiTmV3IHRvIHlvdSI6Ik5ldyB0byB5b3UiLCJ7Y291bnR9IG5ldyB0byB5b3UiOiJ7Y291bnR9IG5ldyB0byB5b3UiLCJFeHBpcmVkIjoiRXhwaXJlZCIsIkV4cGlyZWQgam9iIjoiRXhwaXJlZCA8SGlkZGVuVmlzdWFsbHk+am9iPC9IaWRkZW5WaXN1YWxseT4ifQ==!"),
        () => import(
          /* webpackChunkName: "en-SG-translations" */
          "./translations.json-en-SG-virtual.json!=!../../../../../../../node_modules/.pnpm/virtual-resource-loader@1.0.1/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJOZXciOiJOZXciLCJ7Y291bnR9IG5ldyI6Intjb3VudH0gbmV3IiwiTmV3IHRvIHlvdSI6Ik5ldyB0byB5b3UiLCJ7Y291bnR9IG5ldyB0byB5b3UiOiJ7Y291bnR9IG5ldyB0byB5b3UiLCJFeHBpcmVkIjoiRXhwaXJlZCIsIkV4cGlyZWQgam9iIjoiRXhwaXJlZCA8SGlkZGVuVmlzdWFsbHk+am9iPC9IaWRkZW5WaXN1YWxseT4ifQ==!"
        )
      ),
"en-MY": createLanguage(
        require.resolveWeak("./translations.json-en-MY-virtual.json!=!../../../../../../../node_modules/.pnpm/virtual-resource-loader@1.0.1/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJOZXciOiJOZXciLCJ7Y291bnR9IG5ldyI6Intjb3VudH0gbmV3IiwiTmV3IHRvIHlvdSI6Ik5ldyB0byB5b3UiLCJ7Y291bnR9IG5ldyB0byB5b3UiOiJ7Y291bnR9IG5ldyB0byB5b3UiLCJFeHBpcmVkIjoiRXhwaXJlZCIsIkV4cGlyZWQgam9iIjoiRXhwaXJlZCA8SGlkZGVuVmlzdWFsbHk+am9iPC9IaWRkZW5WaXN1YWxseT4ifQ==!"),
        () => import(
          /* webpackChunkName: "en-MY-translations" */
          "./translations.json-en-MY-virtual.json!=!../../../../../../../node_modules/.pnpm/virtual-resource-loader@1.0.1/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJOZXciOiJOZXciLCJ7Y291bnR9IG5ldyI6Intjb3VudH0gbmV3IiwiTmV3IHRvIHlvdSI6Ik5ldyB0byB5b3UiLCJ7Y291bnR9IG5ldyB0byB5b3UiOiJ7Y291bnR9IG5ldyB0byB5b3UiLCJFeHBpcmVkIjoiRXhwaXJlZCIsIkV4cGlyZWQgam9iIjoiRXhwaXJlZCA8SGlkZGVuVmlzdWFsbHk+am9iPC9IaWRkZW5WaXN1YWxseT4ifQ==!"
        )
      ),
"en-PH": createLanguage(
        require.resolveWeak("./translations.json-en-PH-virtual.json!=!../../../../../../../node_modules/.pnpm/virtual-resource-loader@1.0.1/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJOZXciOiJOZXciLCJ7Y291bnR9IG5ldyI6Intjb3VudH0gbmV3IiwiTmV3IHRvIHlvdSI6Ik5ldyB0byB5b3UiLCJ7Y291bnR9IG5ldyB0byB5b3UiOiJ7Y291bnR9IG5ldyB0byB5b3UiLCJFeHBpcmVkIjoiRXhwaXJlZCIsIkV4cGlyZWQgam9iIjoiRXhwaXJlZCA8SGlkZGVuVmlzdWFsbHk+am9iPC9IaWRkZW5WaXN1YWxseT4ifQ==!"),
        () => import(
          /* webpackChunkName: "en-PH-translations" */
          "./translations.json-en-PH-virtual.json!=!../../../../../../../node_modules/.pnpm/virtual-resource-loader@1.0.1/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJOZXciOiJOZXciLCJ7Y291bnR9IG5ldyI6Intjb3VudH0gbmV3IiwiTmV3IHRvIHlvdSI6Ik5ldyB0byB5b3UiLCJ7Y291bnR9IG5ldyB0byB5b3UiOiJ7Y291bnR9IG5ldyB0byB5b3UiLCJFeHBpcmVkIjoiRXhwaXJlZCIsIkV4cGlyZWQgam9iIjoiRXhwaXJlZCA8SGlkZGVuVmlzdWFsbHk+am9iPC9IaWRkZW5WaXN1YWxseT4ifQ==!"
        )
      ),
"id": createLanguage(
        require.resolveWeak("./translations.json-id-virtual.json!=!../../../../../../../node_modules/.pnpm/virtual-resource-loader@1.0.1/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJOZXciOiJOZXciLCJ7Y291bnR9IG5ldyI6Intjb3VudH0gbmV3IiwiTmV3IHRvIHlvdSI6Ik5ldyB0byB5b3UiLCJ7Y291bnR9IG5ldyB0byB5b3UiOiJ7Y291bnR9IG5ldyB0byB5b3UiLCJFeHBpcmVkIjoiRXhwaXJlZCIsIkV4cGlyZWQgam9iIjoiRXhwaXJlZCA8SGlkZGVuVmlzdWFsbHk+am9iPC9IaWRkZW5WaXN1YWxseT4ifQ==!"),
        () => import(
          /* webpackChunkName: "id-translations" */
          "./translations.json-id-virtual.json!=!../../../../../../../node_modules/.pnpm/virtual-resource-loader@1.0.1/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJOZXciOiJOZXciLCJ7Y291bnR9IG5ldyI6Intjb3VudH0gbmV3IiwiTmV3IHRvIHlvdSI6Ik5ldyB0byB5b3UiLCJ7Y291bnR9IG5ldyB0byB5b3UiOiJ7Y291bnR9IG5ldyB0byB5b3UiLCJFeHBpcmVkIjoiRXhwaXJlZCIsIkV4cGlyZWQgam9iIjoiRXhwaXJlZCA8SGlkZGVuVmlzdWFsbHk+am9iPC9IaWRkZW5WaXN1YWxseT4ifQ==!"
        )
      ),
"th": createLanguage(
        require.resolveWeak("./translations.json-th-virtual.json!=!../../../../../../../node_modules/.pnpm/virtual-resource-loader@1.0.1/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJOZXciOiJOZXciLCJ7Y291bnR9IG5ldyI6Intjb3VudH0gbmV3IiwiTmV3IHRvIHlvdSI6Ik5ldyB0byB5b3UiLCJ7Y291bnR9IG5ldyB0byB5b3UiOiJ7Y291bnR9IG5ldyB0byB5b3UiLCJFeHBpcmVkIjoiRXhwaXJlZCIsIkV4cGlyZWQgam9iIjoiRXhwaXJlZCA8SGlkZGVuVmlzdWFsbHk+am9iPC9IaWRkZW5WaXN1YWxseT4ifQ==!"),
        () => import(
          /* webpackChunkName: "th-translations" */
          "./translations.json-th-virtual.json!=!../../../../../../../node_modules/.pnpm/virtual-resource-loader@1.0.1/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJOZXciOiJOZXciLCJ7Y291bnR9IG5ldyI6Intjb3VudH0gbmV3IiwiTmV3IHRvIHlvdSI6Ik5ldyB0byB5b3UiLCJ7Y291bnR9IG5ldyB0byB5b3UiOiJ7Y291bnR9IG5ldyB0byB5b3UiLCJFeHBpcmVkIjoiRXhwaXJlZCIsIkV4cGlyZWQgam9iIjoiRXhwaXJlZCA8SGlkZGVuVmlzdWFsbHk+am9iPC9IaWRkZW5WaXN1YWxseT4ifQ==!"
        )
      ),
"id-ID": createLanguage(
        require.resolveWeak("./translations.json-id-ID-virtual.json!=!../../../../../../../node_modules/.pnpm/virtual-resource-loader@1.0.1/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJOZXciOiJCYXJ1Iiwie2NvdW50fSBuZXciOiJ7Y291bnR9IGJhcnUiLCJOZXcgdG8geW91IjoiQmFydSBiYWdpIEFuZGEiLCJ7Y291bnR9IG5ldyB0byB5b3UiOiJ7Y291bnR9IGJhcnUgYmFnaSBBbmRhIiwiRXhwaXJlZCI6IkJlcmFraGlyIiwiRXhwaXJlZCBqb2IiOiI8SGlkZGVuVmlzdWFsbHk+TG93b25nYW48L0hpZGRlblZpc3VhbGx5PiBrZWRhbHV3YXJzYSJ9!"),
        () => import(
          /* webpackChunkName: "id-ID-translations" */
          "./translations.json-id-ID-virtual.json!=!../../../../../../../node_modules/.pnpm/virtual-resource-loader@1.0.1/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJOZXciOiJCYXJ1Iiwie2NvdW50fSBuZXciOiJ7Y291bnR9IGJhcnUiLCJOZXcgdG8geW91IjoiQmFydSBiYWdpIEFuZGEiLCJ7Y291bnR9IG5ldyB0byB5b3UiOiJ7Y291bnR9IGJhcnUgYmFnaSBBbmRhIiwiRXhwaXJlZCI6IkJlcmFraGlyIiwiRXhwaXJlZCBqb2IiOiI8SGlkZGVuVmlzdWFsbHk+TG93b25nYW48L0hpZGRlblZpc3VhbGx5PiBrZWRhbHV3YXJzYSJ9!"
        )
      ),
"th-TH": createLanguage(
        require.resolveWeak("./translations.json-th-TH-virtual.json!=!../../../../../../../node_modules/.pnpm/virtual-resource-loader@1.0.1/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJOZXciOiLguYPguKvguKHguYgiLCJ7Y291bnR9IG5ldyI6Intjb3VudH0g4LmD4Lir4Lih4LmIIiwiTmV3IHRvIHlvdSI6IuC5g+C4q+C4oeC5iOC4quC4s+C4q+C4o+C4seC4muC4hOC4uOC4kyIsIntjb3VudH0gbmV3IHRvIHlvdSI6Intjb3VudH0g4LmD4Lir4Lih4LmI4Liq4Liz4Lir4Lij4Lix4Lia4LiE4Li44LiTIiwiRXhwaXJlZCI6IuC4q+C4oeC4lOC4reC4suC4ouC4uOC5geC4peC5ieC4pyIsIkV4cGlyZWQgam9iIjoiPEhpZGRlblZpc3VhbGx5PuC4h+C4suC4mTwvSGlkZGVuVmlzdWFsbHk+4LiX4Li14LmI4Lir4Lih4LiU4Lit4Liy4Lii4Li4In0=!"),
        () => import(
          /* webpackChunkName: "th-TH-translations" */
          "./translations.json-th-TH-virtual.json!=!../../../../../../../node_modules/.pnpm/virtual-resource-loader@1.0.1/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJOZXciOiLguYPguKvguKHguYgiLCJ7Y291bnR9IG5ldyI6Intjb3VudH0g4LmD4Lir4Lih4LmIIiwiTmV3IHRvIHlvdSI6IuC5g+C4q+C4oeC5iOC4quC4s+C4q+C4o+C4seC4muC4hOC4uOC4kyIsIntjb3VudH0gbmV3IHRvIHlvdSI6Intjb3VudH0g4LmD4Lir4Lih4LmI4Liq4Liz4Lir4Lij4Lix4Lia4LiE4Li44LiTIiwiRXhwaXJlZCI6IuC4q+C4oeC4lOC4reC4suC4ouC4uOC5geC4peC5ieC4pyIsIkV4cGlyZWQgam9iIjoiPEhpZGRlblZpc3VhbGx5PuC4h+C4suC4mTwvSGlkZGVuVmlzdWFsbHk+4LiX4Li14LmI4Lir4Lih4LiU4Lit4Liy4Lii4Li4In0=!"
        )
      ),
"en-PSEUDO": createLanguage(
        require.resolveWeak("./translations.json-en-PSEUDO-virtual.json!=!../../../../../../../node_modules/.pnpm/virtual-resource-loader@1.0.1/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJOZXciOiJbw5Hhur3hur3hur3FtV0iLCJ7Y291bnR9IG5ldyI6Ilt7Y291bnR9IOC4geC4teC5ieG6veG6veG6vcW1XSIsIk5ldyB0byB5b3UiOiJbw5Hhur3hur3FtSDhua/DtsO2IMO9w73DtsO2x5rHml0iLCJ7Y291bnR9IG5ldyB0byB5b3UiOiJbe2NvdW50fSDguIHguLXguYnhur3hur3FtSDhua/DtsO2IMO9w73DtsO2x5rHml0iLCJFeHBpcmVkIjoiW8OL6q2VxqXDrMOsw6zFmeG6veG6veG6vcaMXSIsIkV4cGlyZWQgam9iIjoiW8OL6q2VxqXDrMOsw6zFmeG6veG6veG6vcaMIDxIaWRkZW5WaXN1YWxseT7EtcO2w7bDtsOfPC9IaWRkZW5WaXN1YWxseT5dIn0=!"),
        () => import(
          /* webpackChunkName: "en-PSEUDO-translations" */
          "./translations.json-en-PSEUDO-virtual.json!=!../../../../../../../node_modules/.pnpm/virtual-resource-loader@1.0.1/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJOZXciOiJbw5Hhur3hur3hur3FtV0iLCJ7Y291bnR9IG5ldyI6Ilt7Y291bnR9IOC4geC4teC5ieG6veG6veG6vcW1XSIsIk5ldyB0byB5b3UiOiJbw5Hhur3hur3FtSDhua/DtsO2IMO9w73DtsO2x5rHml0iLCJ7Y291bnR9IG5ldyB0byB5b3UiOiJbe2NvdW50fSDguIHguLXguYnhur3hur3FtSDhua/DtsO2IMO9w73DtsO2x5rHml0iLCJFeHBpcmVkIjoiW8OL6q2VxqXDrMOsw6zFmeG6veG6veG6vcaMXSIsIkV4cGlyZWQgam9iIjoiW8OL6q2VxqXDrMOsw6zFmeG6veG6veG6vcaMIDxIaWRkZW5WaXN1YWxseT7EtcO2w7bDtsOfPC9IaWRkZW5WaXN1YWxseT5dIn0=!"
        )
      )
      });
  
      export { translations as default };
    