import { Badge, Box, Inline } from 'braid-design-system';
import type { ComponentProps } from 'react';

export interface JobBadgeProps {
  label: string;
  tone: ComponentProps<typeof Badge>['tone'];
}

const JobBadge = ({ label, tone }: JobBadgeProps) => (
  <Box pointerEvents="none">
    <Badge tone={tone}>{label}</Badge>
  </Box>
);

export const JobBadges = ({ badges }: { badges: JobBadgeProps[] }) =>
  badges.length ? (
    <Inline space="xxsmall">
      {badges.map(({ label, tone }, index) => (
        <JobBadge key={index} label={label} tone={tone} />
      ))}
    </Inline>
  ) : null;
