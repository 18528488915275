import { useTranslations } from '@vocab/react';
import { Heading, Hidden, Stack } from 'braid-design-system';

import AnimatedHeight from 'src/components/AnimatedHeight/AnimatedHeight';

import translations from './.vocab';
import { RecommendedJobCard } from './RecommendedJobCard';
import {
  TOP_SECTION_LIMIT,
  useHomeRecommendations,
} from './useHomeRecommendations';

export const MoreRecommendations = () => {
  const { moreJobs, loading } = useHomeRecommendations();
  const { t } = useTranslations(translations);

  return (
    <AnimatedHeight changeKeys={[loading]}>
      {!loading && moreJobs.length ? (
        <Stack space="small">
          <Hidden above="tablet">
            <Heading level="4" weight="weak">
              {t('More jobs for you')}
            </Heading>
          </Hidden>
          <Stack space="xsmall">
            {moreJobs.map((job, index) => (
              <RecommendedJobCard
                key={`recommendedJob_${job.id}`}
                jobInfo={job}
                position={TOP_SECTION_LIMIT + index + 1}
              />
            ))}
          </Stack>
        </Stack>
      ) : null}
    </AnimatedHeight>
  );
};
