import { Box } from 'braid-design-system';
import { memo, useEffect } from 'react';

import { GoogleOneTapWrapper } from 'src/components/GoogleOneTapWrapper/GoogleOneTapWrapper.tsx';
import { SafetyHelmet } from 'src/components/SafetyHelmet/SafetyHelmet';
import { SearchResultPage } from 'src/handlers/SearchResults';
import useMeasureTimeFirstMountFromResponseEnd from 'src/modules/hooks/useMeasureTimeFirstMountFromResponseEnd';
import {
  PageTitle,
  useSetHubblePage,
  useSetHubbleTags,
} from 'src/modules/hubble';
import { useHomePageHead } from 'src/modules/seo-header/home-page/head';
import { useSelector } from 'src/store/react';
import {
  selectUserTestHeaders,
  selectAuthenticated,
  selectFeatureFlag,
} from 'src/store/selectors';

import { CPCarousel } from './CPCarousel/CPCarousel';
import Dashboard from './Dashboard/Dashboard';
import HomePageBanner from './HomePageBanner/HomePageBanner';
import NewSignedInDashboard from './NewSignedInDashboard/NewSignedInDashboard.tsx';
import QuickSearch from './QuickSearch/QuickSearch';
import RecentSearchesBar from './RecentSearches/RecentSearchesBar';

import * as styles from './HomePage.css';

const sectionSpace = 'xxxlarge';

const HomePage = () => {
  useSetHubblePage(PageTitle.homePage);
  const testHeaders = useSelector(selectUserTestHeaders);
  const isAuthenticated = useSelector(selectAuthenticated);
  useSetHubbleTags(testHeaders);
  useMeasureTimeFirstMountFromResponseEnd('HomePage');
  const newHomePageLayoutEnabled = useSelector(
    selectFeatureFlag('homepageLayoutRefresh'),
  );

  useEffect(() => {
    SearchResultPage.preload();
  }, []);

  const { meta, link, script, title } = useHomePageHead();

  if (isAuthenticated && newHomePageLayoutEnabled) {
    return (
      <Box display="flex" flexDirection="column">
        <SafetyHelmet meta={meta} link={link} script={script} title={title} />
        <Box
          display={{ mobile: 'none', tablet: 'block' }}
          paddingTop={sectionSpace}
          className={styles.reorder}
        >
          <QuickSearch />
        </Box>
        <RecentSearchesBar />
        <Box paddingTop="xlarge">
          <NewSignedInDashboard />
        </Box>
        <Box paddingTop={sectionSpace}>
          <HomePageBanner />
        </Box>
      </Box>
    );
  }

  return (
    <Box display="flex" flexDirection="column">
      <SafetyHelmet meta={meta} link={link} script={script} title={title} />
      <GoogleOneTapWrapper page="homepage" />
      <Box
        display={{ mobile: 'none', tablet: 'block' }}
        paddingTop={sectionSpace}
        className={styles.reorder}
      >
        <QuickSearch />
      </Box>
      <RecentSearchesBar />
      <Box paddingTop="xxlarge">
        <Dashboard />
      </Box>
      <CPCarousel sectionSpace={sectionSpace} />
      <Box paddingTop={sectionSpace}>
        <HomePageBanner />
      </Box>
    </Box>
  );
};

export default memo(HomePage);
