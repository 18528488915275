import type { Country, Zone } from '@seek/audience-zones';
import type { Language } from '@seek/melways-sites';
import { useTranslations } from '@vocab/react';
import { stringify } from 'query-string';

import { useAppConfig } from 'src/config/appConfig';
import getCountryFromZone from 'src/config/utils/getCountryFromZone';
import { isMobileUserAgent } from 'src/modules/chalice-user-agent/device-detect';

import translations from '../.vocab';
import type { BannerProps } from '../HomePageBanner';

// @ts-expect-error
import candidateImage from './GenericAsiaCandidateBanner.png';
// @ts-expect-error
import hirerImage from './GenericAsiaHirerBanner.png';

const useGenericAsiaMarketingContent = (): [BannerProps, BannerProps] => {
  const { t } = useTranslations(translations);

  const { zone, language } = useAppConfig();
  const country = getCountryFromZone(zone);

  return [
    // Candidate
    {
      text: {
        title: t('"Hello" to a better career and salary'),
        inverted: true,
      },
      background: {
        color: '#E60278',
        imageUrl: candidateImage,
      },
      cta: {
        label: t('Get tips and tools'),
        variant: 'solid',
        tone: 'neutral',
        inverted: true,
        linkContext: getLinkContext('candidate'),
      },
      location: getCTALocation(zone, language, country, 'candidate'),
    },
    // Hirer
    {
      text: {
        title: t('"Hello" to better talent matches'),
      },
      background: {
        color: '#E6EAF2',
        imageUrl: hirerImage,
      },
      cta: {
        label: t('Hire for FREE'),
        variant: 'solid',
        tone: 'brandAccent',
        linkContext: getLinkContext('hirer'),
      },
      location: getCTALocation(zone, language, country, 'hirer'),
    },
  ];
};

const candidateCTALocation: Partial<
  Record<Zone, Partial<Record<Language, string>>>
> = {
  'asia-1': {
    en: 'https://hk.jobsdb.com/career-advice/',
  },
  'asia-3': {
    en: 'https://th.jobsdb.com/career-advice/',
    th: 'https://th.jobsdb.com/th/career-advice/',
  },
  'asia-4': {
    en: 'https://id.jobstreet.com/career-advice/',
    id: 'https://id.jobstreet.com/id/career-advice/',
  },
  'asia-5': {
    en: 'https://my.jobstreet.com/career-advice/',
  },
  'asia-6': {
    en: 'https://www.jobstreet.com.ph/career-advice/',
  },
  'asia-7': {
    en: 'https://sg.jobstreet.com/career-advice/',
  },
};

const hirerCTALocation: Partial<
  Record<Zone, Partial<Record<Language, string>>>
> = {
  'asia-1': {
    en: 'https://hk.employer.seek.com/',
  },
  'asia-3': {
    en: 'https://th.employer.seek.com/',
    th: 'https://th.employer.seek.com/th/',
  },
  'asia-4': {
    en: 'https://id.employer.seek.com/',
    id: 'https://id.employer.seek.com/id/',
  },
  'asia-5': {
    en: 'https://my.employer.seek.com/',
  },
  'asia-6': {
    en: 'https://ph.employer.seek.com/',
  },
  'asia-7': {
    en: 'https://sg.employer.seek.com/',
  },
};

const getCTALocation = (
  zone: Zone,
  language: Language,
  country: Country,
  bannerType: string,
): string | undefined => {
  if (!zone || !language) {
    return undefined;
  }

  const utm_campaign = country.toLowerCase();
  const utm_medium = isMobileUserAgent() ? 'mobile_web' : 'desktop';
  const utm_source = 'seek';

  let href;
  let queryParams;
  switch (bannerType) {
    case 'candidate':
      href = candidateCTALocation[zone]?.[language] || '';

      queryParams = stringify({
        tracking: `CTA-WEB-HomepageMarketingBanner-${zone}`,
        utm_content: 'homepage_banner_1',
        utm_source,
        utm_campaign,
        utm_medium,
      });

      break;

    case 'hirer':
      href = hirerCTALocation[zone]?.[language] || '';

      queryParams = stringify({
        tracking: `ILC-WEB-HomepageMarketingBanner-${zone}`,
        utm_content: 'homepage_banner_2',
        utm_source,
        utm_campaign,
        utm_medium,
      });

      break;
  }

  return href ? `${href}?${queryParams}` : undefined;
};

const getLinkContext = (bannerType: string) => ({
  linkAction:
    bannerType === 'candidate' ? 'open career advice' : 'open hirer site',
  linkText: bannerType === 'candidate' ? 'Get tips and tools' : 'Hire for FREE',
});

export default useGenericAsiaMarketingContent;
