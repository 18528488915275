
      import { createLanguage, createTranslationFile } from '@vocab/webpack/web';
      
    const translations = createTranslationFile({
      "en": createLanguage(
        require.resolveWeak("./translations.json-en-virtual.json!=!../../../../../../node_modules/.pnpm/virtual-resource-loader@1.0.1/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJTYXZlZCBqb2JzIjoiU2F2ZWQgam9icyIsIlVzZSB0aGUgU2F2ZS4uLiI6IlVzZSB0aGUgU2F2ZSBidXR0b24gb24gZWFjaCBqb2IgbGlzdGluZyB0byBzYXZlIGl0IGZvciBsYXRlci4gWW91IGNhbiB0aGVuIGFjY2VzcyB0aGVtIG9uIGFsbCB5b3VyIGRldmljZXMuIn0=!"),
        () => import(
          /* webpackChunkName: "en-translations" */
          "./translations.json-en-virtual.json!=!../../../../../../node_modules/.pnpm/virtual-resource-loader@1.0.1/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJTYXZlZCBqb2JzIjoiU2F2ZWQgam9icyIsIlVzZSB0aGUgU2F2ZS4uLiI6IlVzZSB0aGUgU2F2ZSBidXR0b24gb24gZWFjaCBqb2IgbGlzdGluZyB0byBzYXZlIGl0IGZvciBsYXRlci4gWW91IGNhbiB0aGVuIGFjY2VzcyB0aGVtIG9uIGFsbCB5b3VyIGRldmljZXMuIn0=!"
        )
      ),
"en-AU": createLanguage(
        require.resolveWeak("./translations.json-en-AU-virtual.json!=!../../../../../../node_modules/.pnpm/virtual-resource-loader@1.0.1/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJTYXZlZCBqb2JzIjoiU2F2ZWQgam9icyIsIlVzZSB0aGUgU2F2ZS4uLiI6IlVzZSB0aGUgU2F2ZSBidXR0b24gb24gZWFjaCBqb2IgbGlzdGluZyB0byBzYXZlIGl0IGZvciBsYXRlci4gWW91IGNhbiB0aGVuIGFjY2VzcyB0aGVtIG9uIGFsbCB5b3VyIGRldmljZXMuIn0=!"),
        () => import(
          /* webpackChunkName: "en-AU-translations" */
          "./translations.json-en-AU-virtual.json!=!../../../../../../node_modules/.pnpm/virtual-resource-loader@1.0.1/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJTYXZlZCBqb2JzIjoiU2F2ZWQgam9icyIsIlVzZSB0aGUgU2F2ZS4uLiI6IlVzZSB0aGUgU2F2ZSBidXR0b24gb24gZWFjaCBqb2IgbGlzdGluZyB0byBzYXZlIGl0IGZvciBsYXRlci4gWW91IGNhbiB0aGVuIGFjY2VzcyB0aGVtIG9uIGFsbCB5b3VyIGRldmljZXMuIn0=!"
        )
      ),
"en-NZ": createLanguage(
        require.resolveWeak("./translations.json-en-NZ-virtual.json!=!../../../../../../node_modules/.pnpm/virtual-resource-loader@1.0.1/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJTYXZlZCBqb2JzIjoiU2F2ZWQgam9icyIsIlVzZSB0aGUgU2F2ZS4uLiI6IlVzZSB0aGUgU2F2ZSBidXR0b24gb24gZWFjaCBqb2IgbGlzdGluZyB0byBzYXZlIGl0IGZvciBsYXRlci4gWW91IGNhbiB0aGVuIGFjY2VzcyB0aGVtIG9uIGFsbCB5b3VyIGRldmljZXMuIn0=!"),
        () => import(
          /* webpackChunkName: "en-NZ-translations" */
          "./translations.json-en-NZ-virtual.json!=!../../../../../../node_modules/.pnpm/virtual-resource-loader@1.0.1/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJTYXZlZCBqb2JzIjoiU2F2ZWQgam9icyIsIlVzZSB0aGUgU2F2ZS4uLiI6IlVzZSB0aGUgU2F2ZSBidXR0b24gb24gZWFjaCBqb2IgbGlzdGluZyB0byBzYXZlIGl0IGZvciBsYXRlci4gWW91IGNhbiB0aGVuIGFjY2VzcyB0aGVtIG9uIGFsbCB5b3VyIGRldmljZXMuIn0=!"
        )
      ),
"en-HK": createLanguage(
        require.resolveWeak("./translations.json-en-HK-virtual.json!=!../../../../../../node_modules/.pnpm/virtual-resource-loader@1.0.1/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJTYXZlZCBqb2JzIjoiU2F2ZWQgam9icyIsIlVzZSB0aGUgU2F2ZS4uLiI6IlVzZSB0aGUgU2F2ZSBidXR0b24gb24gZWFjaCBqb2IgbGlzdGluZyB0byBzYXZlIGl0IGZvciBsYXRlci4gWW91IGNhbiB0aGVuIGFjY2VzcyB0aGVtIG9uIGFsbCB5b3VyIGRldmljZXMuIn0=!"),
        () => import(
          /* webpackChunkName: "en-HK-translations" */
          "./translations.json-en-HK-virtual.json!=!../../../../../../node_modules/.pnpm/virtual-resource-loader@1.0.1/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJTYXZlZCBqb2JzIjoiU2F2ZWQgam9icyIsIlVzZSB0aGUgU2F2ZS4uLiI6IlVzZSB0aGUgU2F2ZSBidXR0b24gb24gZWFjaCBqb2IgbGlzdGluZyB0byBzYXZlIGl0IGZvciBsYXRlci4gWW91IGNhbiB0aGVuIGFjY2VzcyB0aGVtIG9uIGFsbCB5b3VyIGRldmljZXMuIn0=!"
        )
      ),
"en-TH": createLanguage(
        require.resolveWeak("./translations.json-en-TH-virtual.json!=!../../../../../../node_modules/.pnpm/virtual-resource-loader@1.0.1/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJTYXZlZCBqb2JzIjoiU2F2ZWQgam9icyIsIlVzZSB0aGUgU2F2ZS4uLiI6IlVzZSB0aGUgU2F2ZSBidXR0b24gb24gZWFjaCBqb2IgbGlzdGluZyB0byBzYXZlIGl0IGZvciBsYXRlci4gWW91IGNhbiB0aGVuIGFjY2VzcyB0aGVtIG9uIGFsbCB5b3VyIGRldmljZXMuIn0=!"),
        () => import(
          /* webpackChunkName: "en-TH-translations" */
          "./translations.json-en-TH-virtual.json!=!../../../../../../node_modules/.pnpm/virtual-resource-loader@1.0.1/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJTYXZlZCBqb2JzIjoiU2F2ZWQgam9icyIsIlVzZSB0aGUgU2F2ZS4uLiI6IlVzZSB0aGUgU2F2ZSBidXR0b24gb24gZWFjaCBqb2IgbGlzdGluZyB0byBzYXZlIGl0IGZvciBsYXRlci4gWW91IGNhbiB0aGVuIGFjY2VzcyB0aGVtIG9uIGFsbCB5b3VyIGRldmljZXMuIn0=!"
        )
      ),
"en-ID": createLanguage(
        require.resolveWeak("./translations.json-en-ID-virtual.json!=!../../../../../../node_modules/.pnpm/virtual-resource-loader@1.0.1/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJTYXZlZCBqb2JzIjoiU2F2ZWQgam9icyIsIlVzZSB0aGUgU2F2ZS4uLiI6IlVzZSB0aGUgU2F2ZSBidXR0b24gb24gZWFjaCBqb2IgbGlzdGluZyB0byBzYXZlIGl0IGZvciBsYXRlci4gWW91IGNhbiB0aGVuIGFjY2VzcyB0aGVtIG9uIGFsbCB5b3VyIGRldmljZXMuIn0=!"),
        () => import(
          /* webpackChunkName: "en-ID-translations" */
          "./translations.json-en-ID-virtual.json!=!../../../../../../node_modules/.pnpm/virtual-resource-loader@1.0.1/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJTYXZlZCBqb2JzIjoiU2F2ZWQgam9icyIsIlVzZSB0aGUgU2F2ZS4uLiI6IlVzZSB0aGUgU2F2ZSBidXR0b24gb24gZWFjaCBqb2IgbGlzdGluZyB0byBzYXZlIGl0IGZvciBsYXRlci4gWW91IGNhbiB0aGVuIGFjY2VzcyB0aGVtIG9uIGFsbCB5b3VyIGRldmljZXMuIn0=!"
        )
      ),
"en-SG": createLanguage(
        require.resolveWeak("./translations.json-en-SG-virtual.json!=!../../../../../../node_modules/.pnpm/virtual-resource-loader@1.0.1/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJTYXZlZCBqb2JzIjoiU2F2ZWQgam9icyIsIlVzZSB0aGUgU2F2ZS4uLiI6IlVzZSB0aGUgU2F2ZSBidXR0b24gb24gZWFjaCBqb2IgbGlzdGluZyB0byBzYXZlIGl0IGZvciBsYXRlci4gWW91IGNhbiB0aGVuIGFjY2VzcyB0aGVtIG9uIGFsbCB5b3VyIGRldmljZXMuIn0=!"),
        () => import(
          /* webpackChunkName: "en-SG-translations" */
          "./translations.json-en-SG-virtual.json!=!../../../../../../node_modules/.pnpm/virtual-resource-loader@1.0.1/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJTYXZlZCBqb2JzIjoiU2F2ZWQgam9icyIsIlVzZSB0aGUgU2F2ZS4uLiI6IlVzZSB0aGUgU2F2ZSBidXR0b24gb24gZWFjaCBqb2IgbGlzdGluZyB0byBzYXZlIGl0IGZvciBsYXRlci4gWW91IGNhbiB0aGVuIGFjY2VzcyB0aGVtIG9uIGFsbCB5b3VyIGRldmljZXMuIn0=!"
        )
      ),
"en-MY": createLanguage(
        require.resolveWeak("./translations.json-en-MY-virtual.json!=!../../../../../../node_modules/.pnpm/virtual-resource-loader@1.0.1/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJTYXZlZCBqb2JzIjoiU2F2ZWQgam9icyIsIlVzZSB0aGUgU2F2ZS4uLiI6IlVzZSB0aGUgU2F2ZSBidXR0b24gb24gZWFjaCBqb2IgbGlzdGluZyB0byBzYXZlIGl0IGZvciBsYXRlci4gWW91IGNhbiB0aGVuIGFjY2VzcyB0aGVtIG9uIGFsbCB5b3VyIGRldmljZXMuIn0=!"),
        () => import(
          /* webpackChunkName: "en-MY-translations" */
          "./translations.json-en-MY-virtual.json!=!../../../../../../node_modules/.pnpm/virtual-resource-loader@1.0.1/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJTYXZlZCBqb2JzIjoiU2F2ZWQgam9icyIsIlVzZSB0aGUgU2F2ZS4uLiI6IlVzZSB0aGUgU2F2ZSBidXR0b24gb24gZWFjaCBqb2IgbGlzdGluZyB0byBzYXZlIGl0IGZvciBsYXRlci4gWW91IGNhbiB0aGVuIGFjY2VzcyB0aGVtIG9uIGFsbCB5b3VyIGRldmljZXMuIn0=!"
        )
      ),
"en-PH": createLanguage(
        require.resolveWeak("./translations.json-en-PH-virtual.json!=!../../../../../../node_modules/.pnpm/virtual-resource-loader@1.0.1/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJTYXZlZCBqb2JzIjoiU2F2ZWQgam9icyIsIlVzZSB0aGUgU2F2ZS4uLiI6IlVzZSB0aGUgU2F2ZSBidXR0b24gb24gZWFjaCBqb2IgbGlzdGluZyB0byBzYXZlIGl0IGZvciBsYXRlci4gWW91IGNhbiB0aGVuIGFjY2VzcyB0aGVtIG9uIGFsbCB5b3VyIGRldmljZXMuIn0=!"),
        () => import(
          /* webpackChunkName: "en-PH-translations" */
          "./translations.json-en-PH-virtual.json!=!../../../../../../node_modules/.pnpm/virtual-resource-loader@1.0.1/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJTYXZlZCBqb2JzIjoiU2F2ZWQgam9icyIsIlVzZSB0aGUgU2F2ZS4uLiI6IlVzZSB0aGUgU2F2ZSBidXR0b24gb24gZWFjaCBqb2IgbGlzdGluZyB0byBzYXZlIGl0IGZvciBsYXRlci4gWW91IGNhbiB0aGVuIGFjY2VzcyB0aGVtIG9uIGFsbCB5b3VyIGRldmljZXMuIn0=!"
        )
      ),
"id": createLanguage(
        require.resolveWeak("./translations.json-id-virtual.json!=!../../../../../../node_modules/.pnpm/virtual-resource-loader@1.0.1/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJTYXZlZCBqb2JzIjoiU2F2ZWQgam9icyIsIlVzZSB0aGUgU2F2ZS4uLiI6IlVzZSB0aGUgU2F2ZSBidXR0b24gb24gZWFjaCBqb2IgbGlzdGluZyB0byBzYXZlIGl0IGZvciBsYXRlci4gWW91IGNhbiB0aGVuIGFjY2VzcyB0aGVtIG9uIGFsbCB5b3VyIGRldmljZXMuIn0=!"),
        () => import(
          /* webpackChunkName: "id-translations" */
          "./translations.json-id-virtual.json!=!../../../../../../node_modules/.pnpm/virtual-resource-loader@1.0.1/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJTYXZlZCBqb2JzIjoiU2F2ZWQgam9icyIsIlVzZSB0aGUgU2F2ZS4uLiI6IlVzZSB0aGUgU2F2ZSBidXR0b24gb24gZWFjaCBqb2IgbGlzdGluZyB0byBzYXZlIGl0IGZvciBsYXRlci4gWW91IGNhbiB0aGVuIGFjY2VzcyB0aGVtIG9uIGFsbCB5b3VyIGRldmljZXMuIn0=!"
        )
      ),
"th": createLanguage(
        require.resolveWeak("./translations.json-th-virtual.json!=!../../../../../../node_modules/.pnpm/virtual-resource-loader@1.0.1/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJTYXZlZCBqb2JzIjoiU2F2ZWQgam9icyIsIlVzZSB0aGUgU2F2ZS4uLiI6IlVzZSB0aGUgU2F2ZSBidXR0b24gb24gZWFjaCBqb2IgbGlzdGluZyB0byBzYXZlIGl0IGZvciBsYXRlci4gWW91IGNhbiB0aGVuIGFjY2VzcyB0aGVtIG9uIGFsbCB5b3VyIGRldmljZXMuIn0=!"),
        () => import(
          /* webpackChunkName: "th-translations" */
          "./translations.json-th-virtual.json!=!../../../../../../node_modules/.pnpm/virtual-resource-loader@1.0.1/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJTYXZlZCBqb2JzIjoiU2F2ZWQgam9icyIsIlVzZSB0aGUgU2F2ZS4uLiI6IlVzZSB0aGUgU2F2ZSBidXR0b24gb24gZWFjaCBqb2IgbGlzdGluZyB0byBzYXZlIGl0IGZvciBsYXRlci4gWW91IGNhbiB0aGVuIGFjY2VzcyB0aGVtIG9uIGFsbCB5b3VyIGRldmljZXMuIn0=!"
        )
      ),
"id-ID": createLanguage(
        require.resolveWeak("./translations.json-id-ID-virtual.json!=!../../../../../../node_modules/.pnpm/virtual-resource-loader@1.0.1/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJTYXZlZCBqb2JzIjoiTG93b25nYW4ga2VyamEgdGVyc2ltcGFuIiwiVXNlIHRoZSBTYXZlLi4uIjoiR3VuYWthbiB0b21ib2wgU2ltcGFuIGRpIHNldGlhcCBsb3dvbmdhbiBrZXJqYSBndW5hIG1lbnlpbXBhbm55YSB1bnR1ayBsYWluIHdha3R1LiBLYW11IGRhcGF0IG1lbmdha3Nlc255YSBkaSBzZW11YSBwZXJhbmdrYXQgZGkgbGFpbiB3YWt0dS4ifQ==!"),
        () => import(
          /* webpackChunkName: "id-ID-translations" */
          "./translations.json-id-ID-virtual.json!=!../../../../../../node_modules/.pnpm/virtual-resource-loader@1.0.1/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJTYXZlZCBqb2JzIjoiTG93b25nYW4ga2VyamEgdGVyc2ltcGFuIiwiVXNlIHRoZSBTYXZlLi4uIjoiR3VuYWthbiB0b21ib2wgU2ltcGFuIGRpIHNldGlhcCBsb3dvbmdhbiBrZXJqYSBndW5hIG1lbnlpbXBhbm55YSB1bnR1ayBsYWluIHdha3R1LiBLYW11IGRhcGF0IG1lbmdha3Nlc255YSBkaSBzZW11YSBwZXJhbmdrYXQgZGkgbGFpbiB3YWt0dS4ifQ==!"
        )
      ),
"th-TH": createLanguage(
        require.resolveWeak("./translations.json-th-TH-virtual.json!=!../../../../../../node_modules/.pnpm/virtual-resource-loader@1.0.1/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJTYXZlZCBqb2JzIjoi4Lia4Lix4LiZ4LiX4Li24LiB4LiH4Liy4LiZIiwiVXNlIHRoZSBTYXZlLi4uIjoi4LmD4LiK4LmJ4Lib4Li44LmI4Lih4Lia4Lix4LiZ4LiX4Li24LiB4LiB4Liy4Lij4LiE4LmJ4LiZ4Lir4Liy4Liq4Liz4Lir4Lij4Lix4Lia4Lia4Lix4LiZ4LiX4Li24LiB4LmE4Lin4LmJ4LmD4LiK4LmJ4LmD4LiZ4Lig4Liy4Lii4Lir4Lil4Lix4LiHIOC5gOC4nuC4t+C5iOC4reC4l+C4teC5iOC4hOC4uOC4k+C4quC4suC4oeC4suC4o+C4luC5gOC4guC5ieC4suC4luC4tuC4h+C5hOC4lOC5ieC4l+C4uOC4geC4reC4uOC4m+C4geC4o+C4k+C5jCJ9!"),
        () => import(
          /* webpackChunkName: "th-TH-translations" */
          "./translations.json-th-TH-virtual.json!=!../../../../../../node_modules/.pnpm/virtual-resource-loader@1.0.1/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJTYXZlZCBqb2JzIjoi4Lia4Lix4LiZ4LiX4Li24LiB4LiH4Liy4LiZIiwiVXNlIHRoZSBTYXZlLi4uIjoi4LmD4LiK4LmJ4Lib4Li44LmI4Lih4Lia4Lix4LiZ4LiX4Li24LiB4LiB4Liy4Lij4LiE4LmJ4LiZ4Lir4Liy4Liq4Liz4Lir4Lij4Lix4Lia4Lia4Lix4LiZ4LiX4Li24LiB4LmE4Lin4LmJ4LmD4LiK4LmJ4LmD4LiZ4Lig4Liy4Lii4Lir4Lil4Lix4LiHIOC5gOC4nuC4t+C5iOC4reC4l+C4teC5iOC4hOC4uOC4k+C4quC4suC4oeC4suC4o+C4luC5gOC4guC5ieC4suC4luC4tuC4h+C5hOC4lOC5ieC4l+C4uOC4geC4reC4uOC4m+C4geC4o+C4k+C5jCJ9!"
        )
      ),
"en-PSEUDO": createLanguage(
        require.resolveWeak("./translations.json-en-PSEUDO-virtual.json!=!../../../../../../node_modules/.pnpm/virtual-resource-loader@1.0.1/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJTYXZlZCBqb2JzIjoiW8WgxIPEg8SD4bm94bq94bq94bq9xowgxLXDtsO2w7bDn8WhXSIsIlVzZSB0aGUgU2F2ZS4uLiI6IlvHmcWh4bq94bq94bq9IOG5r+G4qeG6veG6veG6vSDFoMSDxIPEg+G5veG6veG6veG6vSDDn8eax5rHmuG5r+G5r8O2w7bDtuC4geC4teC5iSDDtsO2w7bguIHguLXguYkg4bq94bq94bq9xIPEg8SDw6fhuKkgxLXDtsO2w7bDnyDGmsOsw6zDrMWh4bmvw6zDrMOs4LiB4Li14LmJxKMg4bmvw7bDtsO2IMWhxIPEg8SD4bm94bq94bq94bq9IMOsw6zDrOG5ryDGksO2w7bDtsWZIMaaxIPEg8SD4bmv4bq94bq94bq9xZkuIMOdw7bDtsO2x5rHmseaIMOnxIPEg8SD4LiB4Li14LmJIOG5r+G4qeG6veG6veG6veC4geC4teC5iSDEg8SDxIPDp8On4bq94bq94bq9xaHFoSDhua/huKnhur3hur3hur1tzIIgw7bDtsO24LiB4Li14LmJIMSDxIPEg8aaxpogw73DvcO9w7bDtsO2x5rHmseaxZkgxozhur3hur3hur3hub3DrMOsw6zDp+G6veG6veG6vcWhLl0ifQ==!"),
        () => import(
          /* webpackChunkName: "en-PSEUDO-translations" */
          "./translations.json-en-PSEUDO-virtual.json!=!../../../../../../node_modules/.pnpm/virtual-resource-loader@1.0.1/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJTYXZlZCBqb2JzIjoiW8WgxIPEg8SD4bm94bq94bq94bq9xowgxLXDtsO2w7bDn8WhXSIsIlVzZSB0aGUgU2F2ZS4uLiI6IlvHmcWh4bq94bq94bq9IOG5r+G4qeG6veG6veG6vSDFoMSDxIPEg+G5veG6veG6veG6vSDDn8eax5rHmuG5r+G5r8O2w7bDtuC4geC4teC5iSDDtsO2w7bguIHguLXguYkg4bq94bq94bq9xIPEg8SDw6fhuKkgxLXDtsO2w7bDnyDGmsOsw6zDrMWh4bmvw6zDrMOs4LiB4Li14LmJxKMg4bmvw7bDtsO2IMWhxIPEg8SD4bm94bq94bq94bq9IMOsw6zDrOG5ryDGksO2w7bDtsWZIMaaxIPEg8SD4bmv4bq94bq94bq9xZkuIMOdw7bDtsO2x5rHmseaIMOnxIPEg8SD4LiB4Li14LmJIOG5r+G4qeG6veG6veG6veC4geC4teC5iSDEg8SDxIPDp8On4bq94bq94bq9xaHFoSDhua/huKnhur3hur3hur1tzIIgw7bDtsO24LiB4Li14LmJIMSDxIPEg8aaxpogw73DvcO9w7bDtsO2x5rHmseaxZkgxozhur3hur3hur3hub3DrMOsw6zDp+G6veG6veG6vcWhLl0ifQ==!"
        )
      )
      });
  
      export { translations as default };
    