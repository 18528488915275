import { useQuery } from '@apollo/client';
import { useHubble } from '@seek/hubble';
import { useMemo, type ComponentProps } from 'react';

import { useAppConfig } from 'src/config/appConfig';
import type {
  GetHomeRecommendationsQuery,
  GetHomeRecommendationsQueryVariables,
} from 'src/graphql/graphql';
import { useTimezone } from 'src/hooks/useTimezone';
import { GET_HOME_RECOMMENDATIONS } from 'src/modules/graphql/queries/recommendations';
import { useSelector } from 'src/store/react';
import { selectSmarterSearchCluster } from 'src/store/selectors';

import type JobCard from '../SharedComponent/JobCard/JobCard';

export const TOP_SECTION_LIMIT = 3;
const RECOMMENDATIONS_LIMIT = 50;

export type RecommendedJobs = NonNullable<
  NonNullable<
    NonNullable<GetHomeRecommendationsQuery['viewer']>['recommendations']
  >['recommendedGlobalJobs']
>;

const mapRecommendedJobs = (
  jobs?: RecommendedJobs['globalJobs'],
): Array<ComponentProps<typeof JobCard>['jobInfo']> =>
  jobs?.map((job) => {
    const {
      job: { advertiser, id, title, listedAt, location, salary, products },
      solMetadata,
    } = job;

    return {
      advertiserName: advertiser.name,
      id,
      locationLabel: location.label,
      salaryLabel: salary?.label,
      salaryCurrencyLabel: salary?.currencyLabel ?? undefined,
      solMetadata,
      title,
      listingDateLabel: listedAt?.label,
      srcLogo: products?.branding?.logo?.url,
    };
  }) || [];

export const useHomeRecommendations = () => {
  const { zone, locale } = useAppConfig();
  const hubble = useHubble();
  const visitorId = hubble.visitorId();
  const smarterSearchCluster = useSelector(selectSmarterSearchCluster);
  const timezone = useTimezone();

  const { data, loading, error } = useQuery<
    GetHomeRecommendationsQuery,
    GetHomeRecommendationsQueryVariables
  >(GET_HOME_RECOMMENDATIONS, {
    variables: {
      zone,
      visitorId,
      limit: RECOMMENDATIONS_LIMIT,
      timezone,
      cluster: smarterSearchCluster,
      locale,
    },
    ssr: false,
  });

  const recommendedJobs = data?.viewer?.recommendations?.recommendedGlobalJobs;
  const jobs = useMemo(
    () => mapRecommendedJobs(recommendedJobs?.globalJobs),
    [recommendedJobs?.globalJobs],
  );
  const topJobs = useMemo(() => jobs?.slice(0, TOP_SECTION_LIMIT), [jobs]);
  const moreJobs = useMemo(() => jobs?.slice(TOP_SECTION_LIMIT), [jobs]);

  return {
    rawRecommendedJobs: recommendedJobs,
    topJobs,
    moreJobs,
    loading,
    solMetadata: recommendedJobs?.solMetadata,
    error,
  };
};
