import "src/components/HomePage/HomePageBanner/GenericAsiaBanner/GenericAsiaBanner.css.ts.vanilla.css!=!../../../../../node_modules/.pnpm/@vanilla-extract+webpack-plugin@2.3.13_@types+node@22.5.4_babel-plugin-macros@3.1.0_terser@5._me7phm25udz7jnf52pkglbndqq/node_modules/@vanilla-extract/webpack-plugin/virtualFileLoader/dist/vanilla-extract-webpack-plugin-virtualFileLoader.cjs.js?{\"fileName\":\"src/components/HomePage/HomePageBanner/GenericAsiaBanner/GenericAsiaBanner.css.ts.vanilla.css\",\"source\":\"#H4sIAAAAAAAAA+2STW6DMBCF95zCy7JwRIAQIJvepHLsCbgFGxknoFTcvYoTpNjhp913Z8/7xpr3PJuPrQz6trgy9O0hdKqgx4wroJpLkaMLUW8Yj8zOP3iDtxmvYFoawhgXBdaycfnEPzwBR6m1rF1mbzEVnLRLpBaheFG+IJk92MkMVsIUSsxrNRd4Wj8aveNMl65EjXQk9KtQ8iwY5jUpwKW2LtXyK+SIygsoR1HQANE5EvJxdPRGtnzqH0LbbmHsPvVRWUnlNgV2U7n2eZHNc8N3JdeA24ZQuI3dKdJY1KehnjbmZrw61+JglUmOoiBoertKcxQmsVvdj4Ody5TGJPBtOVmW02U5s+W75/caGCeopQpAICIYeqtJjx87sbsN7hufju1Z43PW58wv2I98F5gMYDWChRDuwDATBBdjEPv4PwgTRJaFfw1i/dFt+Ns9U7KbyjaLJrON4mQ122Qt21cgXQOyKWDwhh81Akx0gQYAAA==\"}!../../../../../node_modules/.pnpm/@vanilla-extract+webpack-plugin@2.3.13_@types+node@22.5.4_babel-plugin-macros@3.1.0_terser@5._me7phm25udz7jnf52pkglbndqq/node_modules/@vanilla-extract/webpack-plugin/extracted.js"
export var background = '_1o0xsgzg';
export var backgroundColour = 'var(--_1o0xsgz0)';
export var backgroundImage = 'var(--_1o0xsgz1)';
export var backgroundPosition = 'var(--_1o0xsgz2)';
export var containerDirection = 'var(--_1o0xsgz5)';
export var containerLayout = '_1o0xsgzd';
export var contentGutter = '_1o0xsgze';
export var contentPaddingBottom = 'var(--_1o0xsgz7)';
export var contentPaddingLeft = 'var(--_1o0xsgz8)';
export var contentPaddingRight = 'var(--_1o0xsgz9)';
export var contentPaddingTop = 'var(--_1o0xsgz6)';
export var image = '_1o0xsgzf';
export var imageHeightVar = 'var(--_1o0xsgza)';
export var imageMinHeightVar = 'var(--_1o0xsgzb)';
export var imageWidthVar = 'var(--_1o0xsgzc)';
export var nowrap = '_1o0xsgzi';
export var responsive = '_1o0xsgzj';
export var title = '_1o0xsgzh';
export var titleFontSize = 'var(--_1o0xsgz4)';
export var titleTopPadding = 'var(--_1o0xsgz3)';